import React from "react";
import ChatWindow from "../../components/ChatSimulator/ChatWindow";
import { useLocation } from "react-router-dom";
import "./ChatbotSimulator.scss";

export default function ChatbotSimulator() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageId = queryParams.get("pageId");

  return (
    <div className="chatbot-simulator">
      <ChatWindow pageId={pageId} />
    </div>
  );
}
