import React from "react";
import { Handle } from "react-flow-renderer";
import "./CarouselNode.scss";

export default function CarouselNode({ id, data }) {
  const stopPropagation = (e) => e.stopPropagation();

  const count = data.selectedCount || 2;
  // Calculate positions for each output handle
  const handlePositions = Array.from({ length: count }, (_, index) => {
    if (count === 1) return "50%";
    const topPercent = 20 + (60 * index) / (count - 1);
    return `${topPercent}%`;
  });

  return (
    <div className="carousel-node">
      <button className="remove-node" onClick={() => data.removeNode(id)}>
        X
      </button>
      <div className="node-content">
        <p className="db-label">Label: {data.label}</p>
        <input
          type="text"
          value={data.label || ""}
          onChange={(e) => data.updateNode(id, { ...data, label: e.target.value })}
          placeholder="Carousel Node Label"
          onMouseDown={stopPropagation}
        />
        <label>Selected Count:</label>
        <input
          type="number"
          value={data.selectedCount || 2}
          onChange={(e) =>
            data.updateNode(id, {
              ...data,
              selectedCount: parseInt(e.target.value, 10) || 0,
            })
          }
          onMouseDown={stopPropagation}
        />
        <p>
          Outputs:{" "}
          {data.outputs && data.outputs.length > 0 ? data.outputs.join(", ") : "None"}
        </p>
      </div>
      {/* Render output handles with unique IDs */}
      {handlePositions.map((top, index) => (
        <Handle
          key={index}
          type="source"
          position="right"
          id={`carousel-output-${index}`}
          isMulti
          style={{ top, transform: "translateY(-50%)" }}
        />
      ))}
      <Handle type="target" position="left" id="carousel-input" />
    </div>
  );
}
