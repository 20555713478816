import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../backend/firebase";
import "./ChatbotTemplatePage.scss";

export default function ChatbotTemplatePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageId = queryParams.get("pageId");

  const [hasExistingFlow, setHasExistingFlow] = useState(false);
  const [loading, setLoading] = useState(true);

  // State for templates, search query, and selected category
  const [templates, setTemplates] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  // Fixed category options
  const categoryOptions = [
    "Hotel",
    "Restaurant",
    "Travel",
    "Marketing",
    "Support",
    "Sales",
    "E-commerce",
    "Education",
    "Healthcare",
    "Finance",
    "Real Estate",
    "Entertainment",
    "Technology",
    "Lifestyle",
    "Retail",
    "Hospitality",
    "Fitness",
    "Beauty",
    "Automotive",
    "Other"
  ];

  useEffect(() => {
    if (!pageId) {
      setLoading(false);
      return;
    }
    checkFlow();
  }, [pageId]);

  // Check for existing flow
  const checkFlow = async () => {
    try {
      const docRef = doc(db, "clients", pageId);
      const snap = await getDoc(docRef);
      if (snap.exists()) {
        const data = snap.data();
        if (data[pageId] && data[pageId].nodes && data[pageId].edges) {
          setHasExistingFlow(true);
        }
      }
    } catch (error) {
      console.error("Error checking existing flow:", error);
    }
    setLoading(false);
  };

  // Fetch templates from "templates" collection and extract the nested data
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templatesRef = collection(db, "templates");
        const templatesSnapshot = await getDocs(templatesRef);
        const templatesList = templatesSnapshot.docs.map((doc) => {
          const data = doc.data();
          // Use the property with the same key as the doc id.
          return {
            id: doc.id,
            ...data[doc.id],
          };
        });
        setTemplates(templatesList);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    fetchTemplates();
  }, []);

  // Filter templates based on search query and selected category.
  // Use template.templateName instead of template.title.
  const filteredTemplates = templates.filter((template) => {
    const title = (template.templateName || "").toLowerCase();
    const cat = (template.category || "").toLowerCase();
    const matchesSearch = title.includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory
      ? cat === selectedCategory.toLowerCase()
      : true;
    return matchesSearch && matchesCategory;
  });

  const handleLoadExisting = () => {
    navigate(`/flow-builder?pageId=${pageId}`);
  };

  // Navigate to flow-builder with the chosen template id.
  const handleUseTemplate = (templateId) => {
    navigate(`/flow-builder?pageId=${pageId}&template=${templateId}`);
  };

  if (loading) {
    return (
      <div className="chatbot-template-page">
        <div className="chatbot-template-page__container">
          <h1>Loading...</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="chatbot-template-page">
      <div className="chatbot-template-page__container">
        <h1>Chatbot Flow Setup</h1>
        {!pageId ? (
          <p>No pageId found in URL. Please return to your dashboard.</p>
        ) : (
          <>
            {hasExistingFlow ? (
              <>
                <p>We found an existing flow for this page.</p>
                <button onClick={handleLoadExisting} className="cta-button">
                  Load Existing Flow
                </button>
                <p>Or start over with a template:</p>
              </>
            ) : (
              <p>No existing flow found. Choose a starting template below:</p>
            )}

            <div className="template-filters">
              <input
                type="text"
                placeholder="Search templates..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">All Categories</option>
                {categoryOptions.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>

            <div className="templates-grid">
  {filteredTemplates.map((template) => (
    <div
      key={template.id}
      className="template-card"
      onClick={() => handleUseTemplate(template.id)}
    >
      <div className="card-container">
        {template.image ? (
          <img
            src={template.image}
            alt={template.templateName}
            className="card-image"
          />
        ) : (
          <div className="card-placeholder">No Image</div>
        )}
        <div className="card-overlay">
          <h2 className="card-title">{template.templateName}</h2>
          <p className="card-category">{template.category}</p>
        </div>
      </div>
    </div>
  ))}
  {filteredTemplates.length === 0 && (
    <p className="no-results">
      No templates found matching your search.
    </p>
  )}
</div>


          </>
        )}
      </div>
    </div>
  );
}
