import React from "react";
import { useLocation } from "react-router-dom";
import AIChatForm from "../../components/AIChatKnowledge/AIChatForm";
import "./AIChatKnowledgePage.scss";

export default function AIChatKnowledgePage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageId = queryParams.get("pageId") || "defaultPage";

  return (
    <div className="ai-chat-knowledge-page">
      <div className="ai-chat-card">
        <AIChatForm pageId={pageId} />
      </div>
    </div>
  );
}
