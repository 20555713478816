import React, { useState } from "react";
import { Handle } from "react-flow-renderer";
import "./CustomNode.scss";

export default function CustomNode({ id, data }) {
  const quickReplies = data?.quickReplies || [];
  const [newQuickReply, setNewQuickReply] = useState({ text: "", targetNodeId: null });

  const handleAddQuickReply = () => {
    if (!newQuickReply.text.trim()) return;
    const updatedQuickReplies = [...quickReplies, newQuickReply];
    data.updateNode(id, { ...data, quickReplies: updatedQuickReplies });
    setNewQuickReply({ text: "", targetNodeId: null });
  };

  const handleQuickReplyChange = (index, value) => {
    const updatedQuickReplies = quickReplies.map((reply, i) =>
      i === index ? { ...reply, text: value } : reply
    );
    data.updateNode(id, { ...data, quickReplies: updatedQuickReplies });
  };

  const handleDeleteQuickReply = (index) => {
    const updatedQuickReplies = quickReplies.filter((_, i) => i !== index);
    data.updateNode(id, { ...data, quickReplies: updatedQuickReplies });
  };

  const stopPropagation = (e) => e.stopPropagation();

  return (
    <div className="custom-node">
      <button
        className="remove-node"
        onClick={(e) => {
          e.stopPropagation();
          data.removeNode(id);
        }}
      >
        X
      </button>

      <input
        type="text"
        value={data.label}
        onChange={(e) =>
          data.updateNode(id, { ...data, label: e.target.value })
        }
        placeholder="Node Title"
        className="node-title"
        onMouseDown={stopPropagation}
        onClick={stopPropagation}
      />

      <textarea
        value={data.text}
        onChange={(e) =>
          data.updateNode(id, { ...data, text: e.target.value })
        }
        placeholder="Message Text"
        className="node-text"
        onMouseDown={stopPropagation}
        onClick={stopPropagation}
      />

      <div className="quick-replies">
        {quickReplies.map((reply, index) => (
          <div key={index} className="quick-reply-container">
            <input
              type="text"
              value={reply.text}
              onChange={(e) => handleQuickReplyChange(index, e.target.value)}
              className="quick-reply-input"
              onMouseDown={stopPropagation}
              onClick={stopPropagation}
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteQuickReply(index);
              }}
              className="delete-quick-reply"
            >
              X
            </button>
        {/* For each quick reply, you have something like: */}
        <Handle
  type="source"
  position="right"
  id={`quickReply-${index}`}
  style={{
    top: '50%',
    transform: 'translateY(-50%)',
    right: '-14px', // adjust as needed so the handle doesn't overlap
  }}
/>

          </div>
        ))}
      </div>

      <div className="add-quick-reply">
        <input
          type="text"
          value={newQuickReply.text}
          onChange={(e) =>
            setNewQuickReply({ ...newQuickReply, text: e.target.value })
          }
          placeholder="Add Quick Reply"
          onMouseDown={stopPropagation}
          onClick={stopPropagation}
        />
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleAddQuickReply();
          }}
        >
          Add
        </button>
      </div>

      <Handle type="target" position="left" />
    </div>
  );
}
