import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../backend/firebase";
import QuickReplies from "../ChatSimulator/QuickReplies";
import "./ChatPhone.scss";

// Helper function to provide a fallback description for an image trigger.
function getFallbackDescription(trigger) {
  return `Here is some info about "${trigger}". It offers great features and an appealing option.`;
}

// Helper to find an image trigger in the text.
function findTriggerInText(imageTriggers = [], text) {
  const lower = text.toLowerCase();
  for (const img of imageTriggers) {
    if (lower.includes(img.trigger.toLowerCase())) {
      return img;
    }
  }
  return null;
}

export default function ChatPhone({ pageId: propPageId, goal, tasks, imageTriggers = [] }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageId = propPageId || queryParams.get("pageId") || "defaultPage";

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [savedFlow, setSavedFlow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
  const MODEL = "gpt-3.5-turbo";

  // Regex for detecting markdown image syntax.
  const imageRegex = /\[([^\]]+)\]\((https?:\/\/.*\.(?:png|jpg|jpeg|gif)(\?.*)?)\)/i;

  // Auto-scroll to the bottom when messages update.
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // Fetch saved chatbot flow from Firestore using the pageId.
  useEffect(() => {
    if (!pageId) return;
    const fetchSavedFlow = async () => {
      try {
        const docRef = doc(db, "clients", pageId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSavedFlow(docSnap.data());
        } else {
          console.log("No saved flow found for page:", pageId);
        }
      } catch (error) {
        console.error("Error fetching saved flow:", error);
      }
    };
    fetchSavedFlow();
  }, [pageId]);

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  // Render an assistant message. If a markdown image is detected, render its caption and image.
  const renderAssistantMessage = (fullContent) => {
    if (!fullContent) return null;
    const match = fullContent.match(imageRegex);
    if (!match) {
      return <div className="message-text">{fullContent.trim()}</div>;
    }
    let caption = match[1].trim();
    const imageUrl = match[2];
    let remainingContent = fullContent.replace(imageRegex, "").trim();
    if (remainingContent.toLowerCase().startsWith("here is an image")) {
      remainingContent = remainingContent.replace(/^here is an image[^a-zA-Z0-9]*/i, "").trim();
    }
    let usedTrigger = null;
    const captionLower = caption.toLowerCase().trim();
    if (captionLower === "!" || captionLower === "") {
      usedTrigger = findTriggerInText(imageTriggers, fullContent);
    } else {
      usedTrigger = imageTriggers.find((img) => captionLower === img.trigger.toLowerCase());
    }
    let fallbackText = "";
    if (usedTrigger) {
      fallbackText = getFallbackDescription(usedTrigger.trigger);
      caption = "";
    }
    if (!remainingContent && !caption && usedTrigger) {
      remainingContent = fallbackText;
    }
    return (
      <div>
        {remainingContent && <div className="message-text">{remainingContent}</div>}
        {caption && <div className="message-caption">{caption}</div>}
        <img className="message-image" src={imageUrl} alt={caption || "image"} />
      </div>
    );
  };

  // Extract file attachment URL from message content.
  const extractFileAttachment = (text) => {
    if (!text) return null;
    const fileRegex = /\[File Attached\]\((https?:\/\/.*\.(?:png|jpg|jpeg|gif)(\?.*)?)\)/i;
    const match = text.match(fileRegex);
    return match ? match[1] : null;
  };

  // Render a user message. If it contains a file attachment, show a thumbnail.
  const renderUserMessage = (fullContent) => {
    if (!fullContent) return null;
    const fileUrl = extractFileAttachment(fullContent);
    if (fileUrl) {
      return (
        <div className="file-attachment">
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            <img src={fileUrl} alt="Attached file" />
          </a>
        </div>
      );
    }
    return <div className="message-text">{fullContent.trim()}</div>;
  };

  // Send user message and fetch AI response using GPT‑3.5‑turbo.
  // The saved flow context is appended as a system message to instruct the AI.
  // IMPORTANT: The AI must never fabricate any phone numbers, emails, websites, or contact details.
  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;
    const userMsg = { role: "user", content: inputMessage.trim() };
    let updatedMessages = [...messages, userMsg];
    setMessages(updatedMessages);
    setInputMessage("");

    let additionalContext = "";
    if (savedFlow) {
      additionalContext = `Chatbot Flow Context: Business Name is "${savedFlow.generalInfo?.name || "unknown"}", Services: ${
        savedFlow.chatbotFlow?.nodes ? Object.keys(savedFlow.chatbotFlow.nodes).join(", ") : "none"
      }. IMPORTANT: Do NOT fabricate any phone numbers, emails, websites, pricing, or contact details. Use only the provided information.`;
    } else {
      additionalContext = `No saved flow data available. Please ask the user for any missing contact details.`;
    }
    const messagesForAPI = [...updatedMessages, { role: "system", content: additionalContext }];

    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: MODEL,
          messages: messagesForAPI,
          temperature: 0.7,
        }),
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error("API error response:", errorText);
        throw new Error(`OpenAI API Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      const aiReply = data.choices[0].message.content;
      setMessages([...updatedMessages, { role: "assistant", content: aiReply }]);
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
      setMessages([
        ...updatedMessages,
        { role: "assistant", content: "Sorry, something went wrong. Please try again later." },
      ]);
    }
  };

  // Handle Enter key press.
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="chat-phone">
      <div className="chat-header">
        <div className="chat-avatar">A6AI</div>
        <p>Business Chat</p>
      </div>
      <div className="chat-body">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.role === "assistant" ? "assistant" : "user"}`}>
            {msg.role === "assistant"
              ? renderAssistantMessage(msg.content)
              : renderUserMessage(msg.content)}
            {msg.quickReplies && (
              <QuickReplies
                replies={msg.quickReplies}
                onSelect={(reply) => {
                  // When a quick reply is selected, add it as a user message.
                  setMessages([...messages, { role: "user", content: reply.text }]);
                }}
              />
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-footer">
        <input
          type="text"
          placeholder="Type your message..."
          value={inputMessage}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
        />
        <button onClick={handleSendMessage} disabled={isLoading}>
          ➤
        </button>
      </div>
    </div>
  );
}
