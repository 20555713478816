import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../backend/firebase";
import "./BuilderOptions.scss";

export default function BuilderOptions() {
  const [userPlan, setUserPlan] = useState("");
  const [loading, setLoading] = useState(true);
  const pageId = new URLSearchParams(window.location.search).get("pageId");

  useEffect(() => {
    const fetchUserPlan = async () => {
      try {
        if (auth.currentUser) {
          const userDocRef = doc(db, "users", auth.currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const data = userDocSnap.data();
            // Use .trim() to ensure proper matching
            setUserPlan((data.selectedPlan || "Standard (Non-AI) Chatbot").trim());
          }
        }
      } catch (error) {
        console.error("Error fetching user plan:", error);
      }
      setLoading(false);
    };
    fetchUserPlan();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="builder-options">
      <div className="builder-options__container">
        {/* HEADER SECTION */}
        <header className="builder-options__header">
          <h1>A6AI Chatbot Platform</h1>
          <p>
          You're one step closer to automating conversations, generating leads, and increasing
sales! <br />
Now, let’s build your chatbot. Choose a builder option below to create a powerful,
engaging experience that converts visitors into customers and keeps them coming back.
          </p>
        </header>

        {/* BUILDER CARDS SECTION */}
        <section className="builder-options__cards">
          <h2>Choose Your Builder</h2>
          <div className="builder-options__card-grid">
            {userPlan === "AI-Powered Chatbot" ? (
              <>
                {/* Flow Builder Card */}
                <div className="builder-card">
                  <div className="builder-card__icon">
                    <i className="fas fa-project-diagram"></i>
                  </div>
                  <h3>Flow Builder</h3>
                  <p>
                  Design and organize your chatbot flows with our intuitive dragand-drop interface. Perfect for structured conversations and
                  guided interactions.
                  </p>
                  <p className="card-category">Category: Chatbot Flow</p>
                  <Link to={`/chatbot-templates?pageId=${pageId}`} className="builder-card__button">
                    Launch Flow Builder
                  </Link>
                </div>

                {/* AI Chat Builder Card */}
                <div className="builder-card">
                  <div className="recommended-badge">Recommended</div>
                  <div className="builder-card__icon">
                    <i className="fas fa-robot"></i>
                  </div>
                  <h3>AI Chat Builder</h3>
                  <p>
                  Leverage real-time AI to generate dynamic, personalized
responses—ideal for boosting engagement and keeping
conversations natural
                  </p>
                  <p className="card-category">Category: AI Chat</p>
                  <Link to={`/ai-chat-builder?pageId=${pageId}`} className="builder-card__button">
                    Launch AI Chat Builder
                  </Link>
                </div>

                {/* Knowledge Builder Card */}
                <div className="builder-card">
                  <div className="recommended-badge">Recommended</div>
                  <div className="builder-card__icon">
                    <i className="fas fa-book"></i>
                  </div>
                  <h3>Knowledge Builder</h3>
                  <p>
                  Integrate documents and data sources so your chatbot delivers
                  context-aware, accurate replies based on real information.
                  </p>
                  <p className="card-category">Category: Knowledge Base</p>
                  <Link to={`/ai-chat-knowledge?pageId=${pageId}`} className="builder-card__button">
                    Launch Knowledge Builder
                  </Link>
                </div>
              </>
            ) : (
              // STANDARD PLAN: Only Flow Builder is available.
              <div className="builder-card">
                <div className="builder-card__icon">
                  <i className="fas fa-project-diagram"></i>
                </div>
                <h3>Flow Builder</h3>
                <p>
                  Build your chatbot using our simple yet powerful flow‑based system.
                </p>
                <p className="card-category">Category: Chatbot Flow</p>
                <Link to={`/chatbot-templates?pageId=${pageId}`} className="builder-card__button">
                  Launch Flow Builder
                </Link>
              </div>
            )}
          </div>
        </section>

        {/* TESTIMONIALS SECTION */}
        <section className="builder-options__testimonials">
          <h2>What Our Clients Say</h2>
          <div className="testimonial">
            <p>
              “A6AI transformed our customer interactions! Our AI‑powered chatbot feels like talking to a real person.”
            </p>
            <span>- Sarah D., CEO at TechCorp</span>
          </div>
          <div className="testimonial">
            <p>
              “We launched a fully functional chatbot in under a day, thanks to the Flow Builder’s simplicity!”
            </p>
            <span>- David M., Founder at Startup Inc.</span>
          </div>
        </section>

        {/* FOOTER SECTION */}
        <footer className="builder-options__footer">
          <p>&copy; {new Date().getFullYear()} A6AI. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
}
