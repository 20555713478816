// FlowChart.jsx snippet
import React from "react";
import ReactFlow, {
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  MiniMap,
  Controls,
  Background,
} from "react-flow-renderer";
import CustomNode from "../CustomNode/CustomNode";
import NormalMessageNode from "../NormalMessageNode/NormalMessageNode";
import ButtonMessageNode from "../ButtonMessageNode/ButtonMessageNode";
import GenericTemplateNode from "../GenericTemplateNode/GenericTemplateNode";
import CarouselNode from "../CarouselNode/CarouselNode";
import "./FlowChart.scss";

const nodeTypes = {
  custom: CustomNode,
  normalMessage: NormalMessageNode,
  buttonMessage: ButtonMessageNode,
  genericTemplate: GenericTemplateNode,
  carousel: CarouselNode,
};

export default function FlowChart({ nodes, edges, setNodes, setEdges, removeNode }) {
  const onNodesChange = (changes) =>
    setNodes((prev) => applyNodeChanges(changes, prev));
  const onEdgesChange = (changes) =>
    setEdges((prev) => applyEdgeChanges(changes, prev));

  const onConnect = (connection) => {
    // e.g. connection.sourceHandle might be "quickReply-0" or "carousel-output-2"
    const handleId = connection.sourceHandle || "";

    let quickReplyIndex = null;
    let carouselIndex = null;

    if (handleId.startsWith("quickReply-")) {
      quickReplyIndex = parseInt(handleId.replace("quickReply-", ""), 10);
    } else if (handleId.startsWith("carousel-output-")) {
      carouselIndex = parseInt(handleId.replace("carousel-output-", ""), 10);
    }

    // Add the new edge with the index info
    setEdges((prevEdges) =>
      addEdge(
        {
          ...connection,
          quickReplyIndex,
          carouselIndex,
        },
        prevEdges
      )
    );
  };

  return (
    <div className="flow-chart">
      <ReactFlow
        nodes={nodes.map((node) => ({
          ...node,
          data: { ...node.data, removeNode },
        }))}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
      >
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
}
