// src/data/flowTemplates.js

const blankTemplate = {
    nodes: [
      {
        id: "welcome",
        type: "custom",
        position: { x: 250, y: 250 },
        data: {
          label: "Welcome Message",
          text: "Hello! How can I help you?",
          quickReplies: [],
          parentId: null,
          childrenIds: [],
        },
      },
    ],
    edges: [],
  };
  
  const sampleTemplate = {
    nodes: [
      {
        id: "welcome",
        type: "normalMessage",
        position: { x: 200, y: 200 },
        data: {
          label: "Welcome Node",
          text: "Welcome to our Chatbot! Choose an option below:",
          quickReplies: [{ text: "Get Info" }, { text: "Contact Us" }],
          parentId: null,
          childrenIds: [],
        },
      },
      {
        id: "infoNode",
        type: "custom",
        position: { x: 500, y: 150 },
        data: {
          label: "Info Node",
          text: "Here is some info about our services.",
          quickReplies: [],
          parentId: "welcome",
          childrenIds: [],
        },
      },
      {
        id: "contactNode",
        type: "buttonMessage",
        position: { x: 500, y: 300 },
        data: {
          label: "Contact Node",
          text: "Reach us via email or phone.",
          buttons: [{ title: "Email Us", text: "email" }],
          quickReplies: [],
          parentId: "welcome",
          childrenIds: [],
        },
      },
    ],
    edges: [
      { source: "welcome", target: "infoNode", quickReplyText: "Get Info" },
      { source: "welcome", target: "contactNode", quickReplyText: "Contact Us" },
    ],
  };
  
  export const flowTemplates = {
    blank: blankTemplate,
    sample: sampleTemplate,
  };
  