import React, { useState, useEffect } from "react";
import { db } from "../../backend/firebase";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Button,
  Select,
  MenuItem,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SaveIcon from "@mui/icons-material/Save";

export default function ClientDemo() {
  const [demos, setDemos] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [newClientName, setNewClientName] = useState("");
  const [newClientDescription, setNewClientDescription] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [editingDemo, setEditingDemo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDemos = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "clientDemos"));
        const demoList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDemos(demoList);
      } catch (error) {
        console.error("Error fetching demos:", error);
      }
    };

    const fetchTemplates = async () => {
      try {
        // Fetch from both "templates" and "aiTemplates"
        const normalSnapshot = await getDocs(collection(db, "templates"));
        const aiSnapshot = await getDocs(collection(db, "aiTemplates"));

        const normalTemplates = normalSnapshot.docs.map((doc) => {
          const data = doc.data();
          if (!data.templateName) {
            const pageId = Object.keys(data)[0];
            const pageData = data[pageId] || {};
            return {
              id: doc.id,
              pageId,
              name: pageData.templateName || "Untitled",
              category: pageData.category || "Uncategorized",
              genre: pageData.genre || "flow",
            };
          }
          return {
            id: doc.id,
            pageId: doc.id,
            name: data.templateName || "Untitled",
            category: data.category || "Uncategorized",
            genre: data.genre || "flow",
          };
        });

        const aiTemplates = aiSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            pageId: doc.id,
            name: data.templateName || "Untitled",
            category: data.category || "Uncategorized",
            genre: data.genre || "ai",
          };
        });

        const combinedTemplates = [...normalTemplates, ...aiTemplates];
        setTemplates(combinedTemplates);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };

    Promise.all([fetchDemos(), fetchTemplates()]).finally(() => setLoading(false));
  }, []);

  const generateDemoLink = async () => {
    if (!newClientName.trim() || !newClientDescription.trim() || !selectedTemplate) {
      alert("⚠️ Please fill in all fields before creating a demo.");
      return;
    }

    const selectedTemplateDoc = templates.find((t) => t.id === selectedTemplate);
    if (!selectedTemplateDoc) {
      alert("❌ Template not found. Please select a valid template.");
      return;
    }

    const templatePageId = selectedTemplateDoc.pageId;
    const genre = selectedTemplateDoc.genre || "flow";

    const demoData = {
      clientName: newClientName.trim(),
      clientDescription: newClientDescription.trim(),
      templateId: selectedTemplate,
      templatePageId,
      createdAt: new Date(),
      genre,
    };

    try {
      const docRef = await addDoc(collection(db, "clientDemos"), demoData);
      let demoURL = `${window.location.origin}/client-demo?demoId=${docRef.id}&pageId=${templatePageId}&isTemplate=true`;
      if (genre === "ai") {
        demoURL += "&genre=ai";
      }
      await updateDoc(doc(db, "clientDemos", docRef.id), { demoURL });
      const updatedDemo = { id: docRef.id, ...demoData, demoURL };
      setDemos((prev) => [...prev, updatedDemo]);

      setNewClientName("");
      setNewClientDescription("");
      setSelectedTemplate("");
    } catch (error) {
      console.error("Error creating demo:", error);
      alert("❌ Failed to create demo. Please try again.");
    }
  };

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
    alert("✅ Demo URL copied to clipboard!");
  };

  const handleDeleteDemo = async (demoId) => {
    const confirmDelete = window.confirm("⚠️ Are you sure you want to delete this demo?");
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, "clientDemos", demoId));
      setDemos((prev) => prev.filter((demo) => demo.id !== demoId));
    } catch (error) {
      console.error("Error deleting demo:", error);
    }
  };

  const handleEditDemo = (demo) => {
    setEditingDemo(demo);
  };

  const handleSaveEdit = async (demoId) => {
    try {
      const genre = editingDemo.genre || "flow";
      let demoURL = `${window.location.origin}/client-demo?demoId=${demoId}&pageId=${editingDemo.templatePageId}&isTemplate=true`;
      if (genre === "ai") {
        demoURL += "&genre=ai";
      }
      const updatedDemo = {
        ...editingDemo,
        demoURL,
      };

      await updateDoc(doc(db, "clientDemos", demoId), updatedDemo);
      setDemos((prev) => prev.map((d) => (d.id === demoId ? updatedDemo : d)));
      setEditingDemo(null);
    } catch (error) {
      console.error("Error updating demo:", error);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        🎯 Manage Client Demos
      </Typography>

      {/* Form for Creating New Demo */}
      <Paper style={{ padding: "20px", marginBottom: "20px" }}>
        <Typography variant="h6" gutterBottom>
          ➕ Create New Demo
        </Typography>
        <TextField
          label="Client Name"
          fullWidth
          margin="normal"
          value={newClientName}
          onChange={(e) => setNewClientName(e.target.value)}
        />
        <TextField
          label="Client Description"
          fullWidth
          margin="normal"
          value={newClientDescription}
          onChange={(e) => setNewClientDescription(e.target.value)}
        />
        <Select
          value={selectedTemplate}
          onChange={(e) => setSelectedTemplate(e.target.value)}
          fullWidth
          displayEmpty
          style={{ marginTop: "10px" }}
        >
          <MenuItem value="" disabled>
            Select a Template
          </MenuItem>
          {templates.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.name} ({template.category}) -{" "}
              {template.genre === "ai" ? "AI Knowledge" : "Flow"}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={generateDemoLink}
          style={{ marginTop: "10px" }}
        >
          🚀 Create Demo
        </Button>
      </Paper>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Client Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Assigned Template</TableCell>
              <TableCell>Genre</TableCell>
              <TableCell>Test</TableCell>
              <TableCell>Copy URL</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {demos.map((demo) => {
              const templateDoc = templates.find((t) => t.id === demo.templateId);
              const demoGenre = templateDoc ? templateDoc.genre : "flow";
              let demoURL = `${window.location.origin}/client-demo?demoId=${demo.id}&pageId=${demo.templatePageId}&isTemplate=true`;
              if (demoGenre === "ai") {
                demoURL += "&genre=ai";
              }
              return (
                <TableRow key={demo.id}>
                  <TableCell>{demo.clientName}</TableCell>
                  <TableCell>{demo.clientDescription}</TableCell>
                  <TableCell>
                    {templates.find((t) => t.id === demo.templateId)?.name || "Unknown"} ({demo.templatePageId})
                  </TableCell>
                  <TableCell>{demoGenre === "ai" ? "AI Knowledge" : "Flow"}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => window.open(demoURL, "_blank")}>
                      <OpenInNewIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => copyToClipboard(demoURL)}>
                      <ContentCopyIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {editingDemo && editingDemo.id === demo.id ? (
                      <IconButton onClick={() => handleSaveEdit(demo.id)}>
                        <SaveIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleEditDemo(demo)}>
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteDemo(demo.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
