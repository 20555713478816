import React from "react";
import QuickReplies from "./QuickReplies";
import GenericTemplate from "./GenericTemplate";
import Carousel from "./Carousel";
import ButtonMessage from "./ButtonMessage";

export default function MessageBubble({ message, onReply }) {
  if (!message) return null;

  // For carousel messages, show only the card(s) without bubble styling.
  if (message.type === "carousel") {
    return (
      <div className="non-bubble-container">
        <Carousel templates={message.templates} />
        {message.quickReplies && message.quickReplies.length > 0 && (
          <QuickReplies replies={message.quickReplies} onSelect={onReply} />
        )}
      </div>
    );
  }

  // For generic template messages, show only the card (no bubble background).
  if (message.type === "genericTemplate") {
    return (
      <div className="non-bubble-container">
        <GenericTemplate template={message.template} />
        {message.quickReplies && message.quickReplies.length > 0 && (
          <QuickReplies replies={message.quickReplies} onSelect={onReply} />
        )}
      </div>
    );
  }

  // For button messages and text messages, use bubble styling.
  const bubbleClass = message.sender === "user" ? "message user" : "message assistant";
  if (message.type === "buttonMessage") {
    return (
      <div className={bubbleClass}>
        <ButtonMessage message={message} onReply={onReply} />
      </div>
    );
  }

  // Default text message bubble.
  return (
    <div className={bubbleClass}>
      <p className="message-text">{message.text}</p>
      {message.quickReplies && message.quickReplies.length > 0 && (
        <QuickReplies replies={message.quickReplies} onSelect={onReply} />
      )}
    </div>
  );
}
