import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  writeBatch,
  query,
  where,
  updateDoc
} from "firebase/firestore";
import { db } from "../../backend/firebase";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

export default function TemplatesPage() {
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  // For selecting genre when creating a new template
  const [selectedGenre, setSelectedGenre] = useState("");

  // Fetch all templates (both normal + AI) on mount
  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      // Grab Flow templates from "templates"
      const flowSnapshot = await getDocs(collection(db, "templates"));
      // Grab AI templates from "aiTemplates"
      const aiSnapshot = await getDocs(collection(db, "aiTemplates"));

      const normalTemplates = flowSnapshot.docs.map((docSnap) => {
        const data = docSnap.data();
        // If doc has top-level fields:
        if (data.templateName) {
          return {
            id: docSnap.id,
            pageId: docSnap.id,
            templateName: data.templateName || "Untitled",
            category: data.category || "Uncategorized",
            genre: data.genre || "flow",
            isDefault: data.isDefault === true, // Firestore might not have isDefault, default to false
          };
        }
        // Otherwise, it might be the older structure with a nested key
        const pageId = Object.keys(data)[0];
        const pageData = data[pageId] || {};
        return {
          id: docSnap.id,
          pageId,
          templateName: pageData.templateName || "Untitled",
          category: pageData.category || "Uncategorized",
          genre: pageData.genre || "flow",
          isDefault: pageData.isDefault === true,
        };
      });

      const aiTemplates = aiSnapshot.docs.map((docSnap) => {
        const data = docSnap.data();
        return {
          id: docSnap.id,
          pageId: docSnap.id,
          templateName: data.templateName || "Untitled",
          category: data.category || "Uncategorized",
          genre: data.genre || "ai",
          // We won't use isDefault for AI templates, but you could track it if needed:
          isDefault: false,
        };
      });

      const combined = [...normalTemplates, ...aiTemplates];
      setTemplates(combined);
      setFilteredTemplates(combined);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  // Re-filter whenever search changes or templates change
  useEffect(() => {
    const filtered = templates.filter((tmpl) => {
      const matchesName = tmpl.templateName
        .toLowerCase()
        .includes(searchName.toLowerCase());
      const matchesCat = tmpl.category
        .toLowerCase()
        .includes(searchCategory.toLowerCase());
      return matchesName && matchesCat;
    });
    setFilteredTemplates(filtered);
  }, [searchName, searchCategory, templates]);

  // Generate a unique 4-5 digit ID for new templates
  const generateUniqueId = () => Math.floor(1000 + Math.random() * 9000);

  // Create a new template with the chosen genre
  const handleCreateNew = () => {
    if (!selectedGenre) {
      alert("Please select a genre first (Flow or AI Knowledge).");
      return;
    }
    const newPageId = generateUniqueId();
    if (selectedGenre === "flow") {
      window.location.href = `/flow-builder?pageId=${newPageId}&special=true&genre=flow`;
    } else {
      window.location.href = `/ai-chat-knowledge?pageId=${newPageId}&special=true&genre=ai`;
    }
  };

  // Edit the selected template
  const handleEdit = (pageId, genre) => {
    if (genre === "flow") {
      window.location.href = `/flow-builder?pageId=${pageId}&special=true&genre=flow`;
    } else {
      window.location.href = `/ai-chat-knowledge?pageId=${pageId}&special=true&genre=ai`;
    }
  };

  /**
   * Called when user clicks "Make Default" on a Flow template.
   * We set isDefault = true for that template doc in "templates",
   * and set isDefault = false for all other Flow templates.
   */
  const handleMakeDefault = async (template) => {
    if (template.genre !== "flow") return; // Only Flow templates can be default
    try {
      // 1) Get all docs in "templates" that are flow
      const flowColl = collection(db, "templates");
      const flowSnapshot = await getDocs(flowColl);

      // 2) Use a write batch to set isDefault = false on all, except the chosen doc
      const batch = writeBatch(db);
      flowSnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        // Attempt to see if this doc is actually top-level or old structure
        let docRef = doc(db, "templates", docSnap.id);
        if (data.templateName || data.isDefault !== undefined) {
          // top-level structure
          if (docSnap.id === template.id) {
            batch.update(docRef, { isDefault: true });
          } else {
            // only un-set default if it's flow
            if ((data.genre || "flow") === "flow") {
              batch.update(docRef, { isDefault: false });
            }
          }
        } else {
          // old structure with dynamic key
          const pageId = Object.keys(data)[0];
          const pageData = data[pageId];
          if ((pageData.genre || "flow") !== "flow") return;
          if (docSnap.id === template.id) {
            // set isDefault = true inside the nested data
            batch.update(docRef, {
              [pageId]: { ...pageData, isDefault: true },
            });
          } else {
            batch.update(docRef, {
              [pageId]: { ...pageData, isDefault: false },
            });
          }
        }
      });

      // 3) Commit the batch, then re-fetch
      await batch.commit();
      alert(`"${template.templateName}" is now the default Flow template!`);
      fetchTemplates();
    } catch (err) {
      console.error("Error setting default template:", err);
      alert("Failed to set default template. Check console for details.");
    }
  };

  // Sort the filtered templates so that any default template is at the top
  const sortedTemplates = [...filteredTemplates].sort((a, b) => {
    // If a is default and b isn't, a goes first
    if (a.isDefault && !b.isDefault) return -1;
    if (!a.isDefault && b.isDefault) return 1;
    return 0;
  });

  return (
    <div style={{ padding: "20px" }}>
      <h2>Manage Templates</h2>

      {/* Genre Selection for creating a new template */}
      <FormControl variant="outlined" size="small" style={{ minWidth: 150, marginBottom: "10px" }}>
        <InputLabel>Genre</InputLabel>
        <Select
          value={selectedGenre}
          onChange={(e) => setSelectedGenre(e.target.value)}
          label="Genre"
        >
          <MenuItem value=""><em>None</em></MenuItem>
          <MenuItem value="flow">Flow</MenuItem>
          <MenuItem value="ai">AI Knowledge</MenuItem>
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateNew}
        style={{ marginLeft: "10px", marginBottom: "10px" }}
      >
        Create New
      </Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TextField label="Search ID" variant="outlined" size="small" disabled />
              </TableCell>
              <TableCell>
                <TextField
                  label="Search Name"
                  variant="outlined"
                  size="small"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Search Category"
                  variant="outlined"
                  size="small"
                  value={searchCategory}
                  onChange={(e) => setSearchCategory(e.target.value)}
                />
              </TableCell>
              <TableCell><strong>Genre</strong></TableCell>
              <TableCell><strong>Actions</strong></TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>ID</strong></TableCell>
              <TableCell><strong>Template Name</strong></TableCell>
              <TableCell><strong>Category</strong></TableCell>
              <TableCell><strong>Genre</strong></TableCell>
              <TableCell><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedTemplates.map((template) => {
              // If it's default, highlight row
              const rowStyle = template.isDefault
                ? { backgroundColor: "#fff6dd" } // light highlight color
                : {};

              return (
                <TableRow key={template.id} style={rowStyle}>
                  <TableCell>{template.id}</TableCell>
                  <TableCell>{template.templateName}</TableCell>
                  <TableCell>{template.category}</TableCell>
                  <TableCell>
                    {template.genre === "ai" ? "AI Knowledge" : "Flow"}
                  </TableCell>
                  <TableCell>
                    {/* Edit Button */}
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => handleEdit(template.pageId, template.genre)}
                      style={{ marginRight: "8px" }}
                    >
                      Edit
                    </Button>

                    {/* If it's a Flow template, show Make Default or "Default" text */}
                    {template.genre === "flow" && (
                      template.isDefault ? (
                        <span style={{ fontWeight: "bold", color: "#4caf50" }}>
                          Default
                        </span>
                      ) : (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleMakeDefault(template)}
                        >
                          Make Default
                        </Button>
                      )
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
