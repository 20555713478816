import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChatIcon from '@mui/icons-material/Chat';
import TicketIcon from '@mui/icons-material/ConfirmationNumber';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ReceiptIcon from '@mui/icons-material/Receipt'; // Icon for Billing

const DashboardListItems = ({ setSelectedSection }) => (
  <React.Fragment>
    <ListItemButton onClick={() => setSelectedSection('Engagement')}>
      <ListItemIcon><ChatIcon /></ListItemIcon>
      <ListItemText primary="Engagement" />
    </ListItemButton>
    <ListItemButton onClick={() => setSelectedSection('Tickets')}>
      <ListItemIcon><TicketIcon /></ListItemIcon>
      <ListItemText primary="Tickets" />
    </ListItemButton>
    <ListItemButton onClick={() => setSelectedSection('Billing')}>
      <ListItemIcon><ReceiptIcon /></ListItemIcon>
      <ListItemText primary="Billing" />
    </ListItemButton>
    <ListItemButton onClick={() => setSelectedSection('Settings')}>
      <ListItemIcon><FacebookIcon /></ListItemIcon>
      <ListItemText primary="Facebook" />
    </ListItemButton>
    <ListItemButton onClick={() => setSelectedSection('Instagram')}>
      <ListItemIcon><InstagramIcon /></ListItemIcon>
      <ListItemText primary="Instagram" />
    </ListItemButton>
  </React.Fragment>
);

export default DashboardListItems;
