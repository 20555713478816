import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../backend/firebase";
import "./ClientDemoView.scss";
import ChatPhone from "../../components/AIChatKnowledge/ChatPhone";
import ChatWindow from "../../components/ChatSimulator/ChatWindow";

export default function ClientDemoView() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const demoId = queryParams.get("demoId");
  const pageId = queryParams.get("pageId");
  const genre = queryParams.get("genre"); // if genre === "ai", show ChatPhone; otherwise, ChatWindow

  const [clientName, setClientName] = useState("");
  const [clientDescription, setClientDescription] = useState("");

  useEffect(() => {
    if (demoId) {
      const fetchDemoInfo = async () => {
        try {
          const docRef = doc(db, "clientDemos", demoId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setClientName(data.clientName);
            setClientDescription(data.clientDescription);
          } else {
            console.error("No client demo found for demoId:", demoId);
          }
        } catch (error) {
          console.error("Error fetching client demo info:", error);
        }
      };
      fetchDemoInfo();
    }
  }, [demoId]);

  return (
    <div className="client-demo">
      <div className="client-demo__container">
        <h1 className="client-demo__title">Your Chatbot Demo</h1>
        {clientName && (
          <h2 className="client-demo__greeting">Hello, {clientName}!</h2>
        )}
        {clientDescription && (
          <p className="client-demo__client-description">
            {clientDescription}
          </p>
        )}
        <p className="client-demo__subtitle">
          Experience the chatbot interaction in real-time.
        </p>
        <div className="client-demo__phone-container no-bg">
          {pageId ? (
            genre === "ai" ? (
              <ChatPhone pageId={pageId} />
            ) : (
              <ChatWindow pageId={pageId} />
            )
          ) : (
            <p className="error-message">
              Invalid demo link. No chatbot found.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
