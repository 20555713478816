import React from "react";
import "./Sidebar.scss";

export default function Sidebar({
  onAddNode,
  onSave,
  specialMode,
  templateName,
  setTemplateName,
  category,
  setCategory,
  categories, // Array of category options
}) {
  return (
    <div className="sidebar">
      <h3>Flow Builder Tools</h3>
      <button onClick={() => onAddNode("custom")} className="add-button">
        Add Custom Stage
      </button>
      <h3>Message Nodes</h3>
      <button onClick={() => onAddNode("normalMessage")} className="add-button normal">
        Add Normal Message
      </button>
      <button onClick={() => onAddNode("buttonMessage")} className="add-button button">
        Add Button Message
      </button>
      <button onClick={() => onAddNode("genericTemplate")} className="add-button generic">
        Add Generic Template
      </button>
      <button onClick={() => onAddNode("carousel")} className="add-button carousel">
        Add Carousel Node
      </button>
      
      {specialMode && (
        <>
          <h3>Template Details</h3>
          <input
            type="text"
            placeholder="Template Name"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            className="template-input"
          />
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="template-dropdown"
          >
            <option value="">Select Category</option>
            {categories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </>
      )}

      <button onClick={onSave} className="save-button">
        Save
      </button>
    </div>
  );
}
