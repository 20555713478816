import React, { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, AppBar, Toolbar, List, Typography, Divider, IconButton, Container, Grid, Drawer } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardListItems from '../../components/DashboardListItems/DashboardListItems';
import Engagement from '../../components/DashboardListItems/Engagement';
import UserTickets from '../../components/Tickets/UserTickets';
import Settings from '../../components/DashboardListItems/Settings';
import Instagram from '../../components/DashboardListItems/Instagram';
import Billing from '../../components/DashboardListItems/Billing'; // Import Billing component

const drawerWidth = 240;

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerStyled = styled(Drawer)(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const defaultTheme = createTheme();

export default function UserDashboard() {
  const [open, setOpen] = useState(true);
  const [selectedSection, setSelectedSection] = useState('Engagement');

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const renderSectionContent = () => {
    switch (selectedSection) {
      case 'Engagement':
        return <Engagement />;
      case 'Tickets':
        return <UserTickets />;
      case 'Billing':
        return <Billing />;
      case 'Settings':
        return <Settings />;
      case 'Instagram':
        return <Instagram />;
      default:
        return <div>Select a section from the menu</div>;
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarStyled position="absolute" open={open}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              User Dashboard
            </Typography>
          </Toolbar>
        </AppBarStyled>
        <DrawerStyled variant="permanent" open={open}>
          <Toolbar />
          <Divider />
          <List component="nav">
            <DashboardListItems setSelectedSection={setSelectedSection} />
          </List>
        </DrawerStyled>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {renderSectionContent()}
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
