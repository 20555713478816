import React, { useState } from "react";
import { Handle } from "react-flow-renderer";
import "./ButtonMessageNode.scss";

export default function ButtonMessageNode({ id, data }) {
  const [newButton, setNewButton] = useState({ title: "", text: "" });

  const handleAddButton = () => {
    if (data.buttons && data.buttons.length >= 2) {
      // Allow only up to two buttons.
      return;
    }
    if (!newButton.title.trim() || !newButton.text.trim()) return;
    const updatedButtons = [...(data.buttons || []), newButton];
    data.updateNode(id, { ...data, buttons: updatedButtons });
    setNewButton({ title: "", text: "" });
  };

  const handleUpdateButton = (index, field, value) => {
    const updatedButtons = data.buttons.map((button, i) =>
      i === index ? { ...button, [field]: value } : button
    );
    data.updateNode(id, { ...data, buttons: updatedButtons });
  };

  const handleDeleteButton = (index) => {
    const updatedButtons = data.buttons.filter((_, i) => i !== index);
    data.updateNode(id, { ...data, buttons: updatedButtons });
  };

  const stopPropagation = (e) => e.stopPropagation();

  return (
    <div className="message-node button">
      <button className="remove-node" onClick={() => data.removeNode(id)}>
        X
      </button>
      <Handle type="target" position="left" />
      <div className="node-content">
        {/* Read-only DB label */}
        <p className="db-label">Label: {data.label}</p>
        {/* Editable node title (using data.label as the primary title) */}
        <input
          type="text"
          value={data.label || ""}
          onChange={(e) =>
            data.updateNode(id, { ...data, label: e.target.value })
          }
          placeholder="Node Title"
          onMouseDown={stopPropagation}
        />
        {/* Message text */}
        <textarea
          value={data.text}
          onChange={(e) =>
            data.updateNode(id, { ...data, text: e.target.value })
          }
          placeholder="Message Text"
          onMouseDown={stopPropagation}
        />
        {/* Buttons (max 2) */}
        <div className="buttons">
          {(data.buttons || []).map((button, index) => (
            <div key={index} className="button-item">
              <input
                type="text"
                value={button.title}
                onChange={(e) =>
                  handleUpdateButton(index, "title", e.target.value)
                }
                placeholder="Button Title"
                onMouseDown={stopPropagation}
              />
              <input
                type="text"
                value={button.text}
                onChange={(e) =>
                  handleUpdateButton(index, "text", e.target.value)
                }
                placeholder="Button Text"
                onMouseDown={stopPropagation}
              />
              <button onClick={() => handleDeleteButton(index)}>X</button>
            </div>
          ))}
          {data.buttons && data.buttons.length < 2 && (
            <>
              <input
                type="text"
                value={newButton.title}
                onChange={(e) =>
                  setNewButton({ ...newButton, title: e.target.value })
                }
                placeholder="New Button Title"
                onMouseDown={stopPropagation}
              />
              <input
                type="text"
                value={newButton.text}
                onChange={(e) =>
                  setNewButton({ ...newButton, text: e.target.value })
                }
                placeholder="New Button Text"
                onMouseDown={stopPropagation}
              />
              <button onClick={handleAddButton}>Add Button</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
