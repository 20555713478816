import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Paper,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert
} from '@mui/material';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { db, auth } from '../../backend/firebase';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const Billing = () => {
  const [userData, setUserData] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [manageModalOpen, setManageModalOpen] = useState(false);
  const [buyModalOpen, setBuyModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [buyError, setBuyError] = useState('');
  const [cancelError, setCancelError] = useState('');
  const currentUserUID = auth.currentUser ? auth.currentUser.uid : null;

  // Stub for invoice PDF download.
  const handleDownloadInvoice = (invoiceId) => {
    alert(`Download PDF for invoice: ${invoiceId}`);
  };

  // Cancel subscription using /cancel-subscription.
  const handleCancelBilling = async () => {
    try {
      const response = await fetch("https://a6ai-f11e1.uc.r.appspot.com/cancel-subscription", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uid: currentUserUID }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage("Subscription cancelled successfully. Your service will end on the effective cancellation date.");
        setUserData({ ...userData, isActiveSubscription: false, hasPaid: false, cancelRequested: true });
      } else {
        setCancelError(data.error || "Cancellation failed.");
      }
    } catch (error) {
      setCancelError(error.message);
    }
    setCancelDialogOpen(false);
    setManageModalOpen(false);
  };

  // Renew subscription using /renew-subscription.
  const handleRenewSubscription = async () => {
    try {
      const response = await fetch("https://a6ai-f11e1.uc.r.appspot.com//renew-subscription", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uid: currentUserUID }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage("Subscription renewed successfully.");
        setUserData({ ...userData, cancelRequested: false });
      } else {
        setCancelError(data.error || "Renewal failed.");
      }
    } catch (error) {
      setCancelError(error.message);
    }
    setManageModalOpen(false);
  };

  // Upgrade plan using /upgrade-plan.
  const handleUpgradePlan = async () => {
    try {
      const response = await fetch("https://a6ai-f11e1.uc.r.appspot.com//upgrade-plan", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uid: currentUserUID }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message || "Plan upgraded successfully.");
        setUserData({ ...userData, selectedPlan: "AI-Powered Chatbot" });
      } else {
        alert(data.error || "Upgrade failed.");
      }
    } catch (error) {
      alert(error.message);
    }
    setManageModalOpen(false);
  };

  // Buy contacts: opens a modal for selecting a package.
  const handleBuyContacts = async (creditPackage) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        alert("Please log in to purchase contacts.");
        return;
      }
      const response = await fetch("https://a6ai-f11e1.uc.r.appspot.com//buy-credit", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          uid: user.uid,
          email: user.email,
          credit_package: creditPackage,
        }),
      });
      const data = await response.json();
      if (data.sessionUrl) {
        // Redirect to Stripe Checkout.
        window.location.href = data.sessionUrl;
      } else {
        setBuyError(data.error || "Error creating checkout session.");
      }
    } catch (error) {
      setBuyError(error.message);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUserUID) {
        try {
          const userRef = doc(db, 'users', currentUserUID);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setUserData(userSnap.data());
          }
        } catch (error) {
          console.error("Error fetching user data", error);
        }
      }
    };

    const fetchInvoices = async () => {
      if (currentUserUID) {
        try {
          const invoicesRef = collection(db, 'users', currentUserUID, 'invoices');
          const snapshot = await getDocs(invoicesRef);
          const invData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setInvoices(invData);
        } catch (error) {
          console.error("Error fetching invoices", error);
        }
      }
    };

    Promise.all([fetchUserData(), fetchInvoices()]).then(() => setLoading(false));
  }, [currentUserUID]);

  if (loading) return <Typography>Loading billing data...</Typography>;
  if (!userData) return <Typography>No billing data available.</Typography>;

  // Calculate usage.
  const contactLimit = Number(userData.contactLimit) || 1500;
  const contactsUsed = Number(userData.contactsUsed) || 0;
  const boughtContacts = Number(userData.boughtContacts) || 0;
  const totalContacts = contactLimit + boughtContacts;
  const usagePercent = totalContacts > 0 ? Math.min((contactsUsed / totalContacts) * 100, 100) : 0;
  const dailyUsage = userData.dailyUsage || [];

  // Determine Subscription End and Renewal Info.
  const subscriptionEnd = userData.cancelRequested && userData.cancelEffectiveDate
    ? new Date(userData.cancelEffectiveDate.seconds * 1000).toLocaleString()
    : "Active";
  const subscriptionRenew = userData.nextBillingDate
    ? new Date(userData.nextBillingDate.seconds * 1000).toLocaleString()
    : "N/A";

  const UsageCircle = () => (
    <Box sx={{ position: 'relative', display: 'inline-flex', mt: 2 }}>
      <CircularProgress variant="determinate" value={100} size={120} thickness={6} sx={{ color: '#bdbdbd', position: 'absolute' }} />
      <CircularProgress variant="determinate" value={usagePercent} size={120} thickness={6} sx={{ color: usagePercent > 80 ? '#d32f2f' : '#1976d2' }} />
      <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="subtitle2" component="div" color="text.secondary">{`${Math.round(usagePercent)}%`}</Typography>
      </Box>
    </Box>
  );

  return (
    <Paper sx={{ p: 3, mt: 2 }}>
      <Typography variant="h5" gutterBottom>Billing</Typography>
      <Divider sx={{ mb: 2 }} />
      {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
      {buyError && <Alert severity="error" sx={{ mb: 2 }}>{buyError}</Alert>}
      {cancelError && <Alert severity="error" sx={{ mb: 2 }}>{cancelError}</Alert>}

      {/* Subscription & Usage Section */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>Current Plan: {userData.selectedPlan || "N/A"}</Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Usage: {contactsUsed} / {totalContacts} contacts used
        </Typography>
        <UsageCircle />
        <Typography variant="body2" sx={{ mt: 2 }}>
          Extra Usage Cost: ${userData.extraUsageCost || 0}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Subscription Renewal: {subscriptionRenew}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Subscription End: {subscriptionEnd}
        </Typography>
      </Box>

      {/* Buy Contacts Section (Button opens Modal) */}
      <Box sx={{ mb: 3 }}>
        <Button variant="contained" onClick={() => setBuyModalOpen(true)}>
          Buy Additional Contacts
        </Button>
      </Box>

      <Dialog open={buyModalOpen} onClose={() => setBuyModalOpen(false)}>
        <DialogTitle>Buy Contacts</DialogTitle>
        <DialogContent>
          <Typography>Select a package to add contacts to your plan:</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <Button variant="contained" onClick={() => { setBuyModalOpen(false); handleBuyContacts("1000"); }}>
              Buy 1000 Contacts ($5)
            </Button>
            <Button variant="contained" onClick={() => { setBuyModalOpen(false); handleBuyContacts("2000"); }}>
              Buy 2000 Contacts ($9)
            </Button>
            <Button variant="contained" onClick={() => { setBuyModalOpen(false); handleBuyContacts("3000"); }}>
              Buy 3000 Contacts ($13)
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBuyModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Manage Subscription Button */}
      <Box sx={{ mb: 3 }}>
        <Button variant="contained" color="info" onClick={() => setManageModalOpen(true)}>
          Manage Subscription
        </Button>
      </Box>

      <Dialog open={manageModalOpen} onClose={() => setManageModalOpen(false)}>
        <DialogTitle>Manage Subscription</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>Choose an action:</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {userData.cancelRequested && (
              <Button variant="contained" color="primary" onClick={handleRenewSubscription}>
                Renew Subscription
              </Button>
            )}
            {userData.selectedPlan === "Standard (Non-AI) Chatbot" && (
              <Button variant="contained" color="secondary" onClick={handleUpgradePlan}>
                Upgrade to AI-Powered Chatbot
              </Button>
            )}
            <Button variant="contained" color="error" onClick={() => setCancelDialogOpen(true)}>
              Cancel Subscription
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setManageModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Cancel Subscription Dialog */}
      <Dialog open={cancelDialogOpen} onClose={() => setCancelDialogOpen(false)}>
        <DialogTitle>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel your subscription? Your service will stop at the end of your current billing cycle (approximately 30 days after your last payment). This cancellation will be recorded in your account.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialogOpen(false)}>No</Button>
          <Button onClick={handleCancelBilling} color="error">Yes, Cancel Subscription</Button>
        </DialogActions>
      </Dialog>

      {/* Daily Contact Spending (Bar Chart) */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>Daily Contact Spending</Typography>
        {dailyUsage.length === 0 ? (
          <Typography>No usage data found.</Typography>
        ) : (
          <ResponsiveContainer width="100%" height={250}>
            <BarChart data={dailyUsage} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="contacts" fill="#1976d2" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>

      {/* Invoice History */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>Invoice History</Typography>
        {invoices.length === 0 ? (
          <Typography>No invoices found.</Typography>
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Invoice ID</TableCell>
                <TableCell>Amount Paid</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Paid Time</TableCell>
                <TableCell>Subscription ID</TableCell>
                <TableCell>Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((inv) => (
                <TableRow key={inv.invoiceId || inv.id}>
                  <TableCell>{inv.invoiceId || inv.id}</TableCell>
                  <TableCell>${inv.amountPaid || 'N/A'}</TableCell>
                  <TableCell>{inv.currency ? inv.currency.toUpperCase() : 'N/A'}</TableCell>
                  <TableCell>{inv.status || 'N/A'}</TableCell>
                  <TableCell>{inv.paidTime ? new Date(inv.paidTime.seconds * 1000).toLocaleString() : 'N/A'}</TableCell>
                  <TableCell>{inv.subscriptionId || 'N/A'}</TableCell>
                  <TableCell>
                    <Button variant="outlined" size="small" onClick={() => handleDownloadInvoice(inv.invoiceId || inv.id)}>
                      Download PDF
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>

      {/* Additional Info */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Last Invoice ID:</strong> {userData.lastInvoiceId || 'N/A'}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Subscription Start:</strong>{" "}
          {userData.subscriptionStartDate ? new Date(userData.subscriptionStartDate.seconds * 1000).toLocaleString() : 'N/A'}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Subscription Renewal:</strong>{" "}
          {userData.nextBillingDate ? new Date(userData.nextBillingDate.seconds * 1000).toLocaleString() : 'N/A'}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Subscription End:</strong>{" "}
          {userData.cancelRequested && userData.cancelEffectiveDate
            ? new Date(userData.cancelEffectiveDate.seconds * 1000).toLocaleString()
            : "Active"}
        </Typography>
        {userData.cancelRequested && (
          <Alert severity="error" sx={{ mt: 1 }}>
            Your subscription has been cancelled and will end on the effective cancellation date.
          </Alert>
        )}
      </Box>
    </Paper>
  );
};

export default Billing;
