import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './Pages/home/Home'; 
import SignIn from './Pages/login/signin';
import SignUp from './Pages/signup/signup';
import CustomizationPage from './Pages/customizationpage/customizationpage'; 
import CustomizationPremium from './Pages/premium/customization'; 

import EditPage from './Pages/editPage/editPage'; 

import AdminDashboard from './Pages/dashboard/Dashboard'; 
import UserDashboard from './Pages/userDashboard/UserDashboard';

import ConnectFacebook from './Pages/ConnectFacebook/ConnectFacebook';
import Connect from './Pages/ConnectFacebook/connect';


import Payment from './Pages/payment/Payment';
import AboutUs from './Pages/other/about_us/AboutUs';
import PrivacyPolicy from './Pages/other/privacy/PrivacyPolicy';
import Demo from './Pages/demo/Demo';
import ReactGA from "react-ga4";

import TermsConditions from './Pages/other/privacy/TermsConditions';
import ProtectedRoute from './hoc/ProtectedRoute'; 
import AdminProtectedRoute from './hoc/AdminProtectedRoute'; 

//this is lastest folder
import FlowBuilder from './Pages/FlowBuilder/FlowBuilder'; // New FlowBuilder Page
import ChatbotSimulator from "./Pages/ChatbotSimulator/ChatbotSimulator";
import AIChatBuilder from "./Pages/AIChatBuilder/AIChatBuilder";
import AIChatKnowledgePage from "./Pages/AIChatKnowledge/AIChatKnowledgePage";
import BuilderOptions from "./Pages/builder-options/BuilderOptions";

import { UserPlanProvider } from "./components/UserPlanContext/UserPlanContext";
import ChatbotTemplatePage from './Pages/chatbot-template/ChatbotTemplatePage';
import ClientDemoView from './Pages/ClienttDemo/ClientDemo';
function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
}

function PageTracker() {
  usePageViews();
  return null;
}

function App() {
  ReactGA.initialize("G-NEHB369D22");

  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/customize" element={<ProtectedRoute><CustomizationPage /></ProtectedRoute>} />
        <Route path="/premium" element={<ProtectedRoute><CustomizationPremium /></ProtectedRoute>} />
        <Route path="/edit/:pageId" element={<ProtectedRoute><EditPage /></ProtectedRoute>} />
        <Route path="/a6ai-admin" element={<AdminProtectedRoute><AdminDashboard /></AdminProtectedRoute>} />
        <Route path="/dashboard" element={<ProtectedRoute><UserDashboard /></ProtectedRoute>} />
        <Route path="/connect-facebook" element={<ProtectedRoute><ConnectFacebook /></ProtectedRoute>} />
        <Route path="/connect" element={<ProtectedRoute><Connect /></ProtectedRoute>} />
        <Route path="/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/flow-builder" element={<ProtectedRoute><FlowBuilder /></ProtectedRoute>} /> {/* New Route */}
        <Route path="/chatbot-simulator" element={<ChatbotSimulator />} />
        <Route path="/ai-chat-builder" element={<AIChatBuilder />} />
        <Route path="/ai-chat-knowledge" element={<ProtectedRoute><AIChatKnowledgePage /></ProtectedRoute>} />
        <Route path="/builder-options" element={<BuilderOptions />} />
        <Route path="/chatbot-templates" element={< ChatbotTemplatePage/>} />
        <Route path="/client-demo" element={<ClientDemoView />} />


      </Routes>
    </Router>
  );
}

export default App;



// TODO - fix the contact payment(wait for the client resposen, if is pay as yoy go or prepay conatcts)
// TODO - fix the clinet demo page
// TODO - make the contact usage restet after 30 days from the payng date
// TODO - add thhe cancelation of the subscription
// TODO - add the Upgrade of the subscription
// TODO - add the down grade of the subscription
// TODO - add the payment history
// TODO - add the contact usage history

// TODO - Usage Tracking & Pay-as-you-go Billing – A function to track user API calls and charge accordingly.
// TODO - Email Sending Service – A function to send emails to users.


// TODO - create emial service on: 
      // Registration	Welcome email	On new user signup
      // Payment Successful	Thank you + Invoice	invoice.payment_succeeded webhook
      // Payment Failed	Alert user to retry	invoice.payment_failed webhook
      // Upcoming Renewal	Reminder before charge	invoice.upcoming or Firebase Scheduler
      // Usage Limit Reached	Notify to upgrade	Custom function when usage limit hits
      // Subscription Canceled	Confirmation + Reactivation link	customer.subscription.deleted webhook
