import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../backend/firebase';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Divider,
  Tabs,
  Tab
} from '@mui/material';

// Updated default settings with a new "contactLimit" field for each plan.
const defaultPricingSettings = {
  pricing: { basic: 0.01, advanced: 0.02 },
  plans: [
    {
      name: "Standard (Non-AI) Chatbot",
      priceText: "$15/month (up to 1,500 contacts)",
      amountCents: 1500,
      contactLimit: 1500,  // New field: maximum contacts included
      fixedPriceId: "price_1R08vjFdAL55zFRt3R4UmMeW", // Fixed price ID for base fee
      usagePriceId: "price_1R08vjFdAL55zFRtmEermSxL",   // Usage (metered) price ID
      color: "#008556",
      buttonText: "Choose Standard",
      features: [
        "Flow Builder with pre-configured templates",
        "No AI-generated responses, only predefined flows",
        "Support for structured chatbot UI: buttons, quick replies, message flows",
        "Webhook support (limited to predefined responses)",
        "AI assistance for chatbot creation (NOT for responses)",
        "Prebuilt chatbot templates for faster setup",
        "Pricing scales based on the number of contacts engaged"
      ],
      pricingTable: [
        { contacts: 500, price: 15 },
        { contacts: 2500, price: 25 },
        { contacts: 5000, price: 45 },
        { contacts: 10000, price: 65 },
        { contacts: 20000, price: 125 }
      ]
    },
    {
      name: "AI-Powered Chatbot",
      priceText: "$30/month (up to 1,500 contacts)",
      amountCents: 3000,
      contactLimit: 1500,  // New field for AI plan as well
      fixedPriceId: "your_fixed_price_id_for_ai", // Replace with your actual fixed price ID
      usagePriceId: "your_usage_price_id_for_ai",   // Replace with your actual usage price ID
      color: "#2F4F9E",
      buttonText: "Choose AI Chatbot",
      features: [
        "AI dynamically generates responses in real-time",
        "Supports structured + unstructured conversations",
        "Uses GPT-3.5 Turbo or GPT-4 for responses",
        "Ability to integrate uploaded documents/knowledge base for AI responses",
        "Three AI chatbot options: Flow Builder + AI, Fully AI Chat, AI + Knowledge Base",
        "Advanced analytics and monitoring tools",
        "Pricing scales based on the number of contacts engaged"
      ],
      pricingTable: [
        { contacts: 500, price: 30 },
        { contacts: 2500, price: 50 },
        { contacts: 5000, price: 90 },
        { contacts: 10000, price: 130 },
        { contacts: 20000, price: 250 }
      ]
    }
  ],
  contactSettings: {
    contactsDefault: 0,
    slider: { min: 0, max: 2000000, step: 500 }
  }
};

const PricingSettings = () => {
  const [settings, setSettings] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchAndInitialize = async () => {
      const settingsRef = doc(db, 'settings', 'pricing');
      const docSnap = await getDoc(settingsRef);
      if (!docSnap.exists()) {
        await setDoc(settingsRef, defaultPricingSettings);
        setSettings(defaultPricingSettings);
      } else {
        setSettings(docSnap.data());
      }
    };
    fetchAndInitialize();
  }, []);

  if (!settings) {
    return <div>Loading pricing settings...</div>;
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const currentPlan = settings.plans[activeTab];

  // Update functions for plan details
  const updatePlanField = (field, value) => {
    const newPlans = [...settings.plans];
    newPlans[activeTab] = { ...newPlans[activeTab], [field]: value };
    setSettings({ ...settings, plans: newPlans });
  };

  // Update functions for features list
  const updateFeature = (index, value) => {
    const newPlans = [...settings.plans];
    const newFeatures = [...newPlans[activeTab].features];
    newFeatures[index] = value;
    newPlans[activeTab] = { ...newPlans[activeTab], features: newFeatures };
    setSettings({ ...settings, plans: newPlans });
  };

  const addFeature = () => {
    const newPlans = [...settings.plans];
    const newFeatures = newPlans[activeTab].features ? [...newPlans[activeTab].features, ''] : [''];
    newPlans[activeTab] = { ...newPlans[activeTab], features: newFeatures };
    setSettings({ ...settings, plans: newPlans });
  };

  const removeFeature = (index) => {
    const newPlans = [...settings.plans];
    const newFeatures = newPlans[activeTab].features.filter((_, i) => i !== index);
    newPlans[activeTab] = { ...newPlans[activeTab], features: newFeatures };
    setSettings({ ...settings, plans: newPlans });
  };

  // Update functions for pricing table rows
  const updatePricingRow = (rowIndex, field, value) => {
    const newPlans = [...settings.plans];
    const newPricingTable = [...newPlans[activeTab].pricingTable];
    newPricingTable[rowIndex] = {
      ...newPricingTable[rowIndex],
      [field]: field === 'contacts' ? parseInt(value) : parseFloat(value)
    };
    newPlans[activeTab] = { ...newPlans[activeTab], pricingTable: newPricingTable };
    setSettings({ ...settings, plans: newPlans });
  };

  const addPricingRow = () => {
    const newPlans = [...settings.plans];
    const newPricingTable = newPlans[activeTab].pricingTable
      ? [...newPlans[activeTab].pricingTable, { contacts: 0, price: 0 }]
      : [{ contacts: 0, price: 0 }];
    newPlans[activeTab] = { ...newPlans[activeTab], pricingTable: newPricingTable };
    setSettings({ ...settings, plans: newPlans });
  };

  const removePricingRow = (rowIndex) => {
    const newPlans = [...settings.plans];
    const newPricingTable = newPlans[activeTab].pricingTable.filter((_, i) => i !== rowIndex);
    newPlans[activeTab] = { ...newPlans[activeTab], pricingTable: newPricingTable };
    setSettings({ ...settings, plans: newPlans });
  };

  const handleSave = async () => {
    const settingsRef = doc(db, 'settings', 'pricing');
    try {
      await updateDoc(settingsRef, settings);
      alert("Pricing settings updated successfully!");
    } catch (e) {
      console.error("Error updating settings", e);
      alert("Failed to update settings.");
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, maxWidth: 1000, margin: '20px auto' }}>
      <Typography variant="h5" gutterBottom>
        Edit Pricing Settings
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Tabs value={activeTab} onChange={handleTabChange} centered sx={{ mb: 3 }}>
        {settings.plans.map((plan, index) => (
          <Tab key={index} label={plan.name} />
        ))}
      </Tabs>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Plan Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Plan Name"
              value={currentPlan.name}
              onChange={(e) => updatePlanField('name', e.target.value)}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Price Text"
              value={currentPlan.priceText}
              onChange={(e) => updatePlanField('priceText', e.target.value)}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Amount (cents)"
              type="number"
              value={currentPlan.amountCents}
              onChange={(e) => updatePlanField('amountCents', parseInt(e.target.value))}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Button Text"
              value={currentPlan.buttonText}
              onChange={(e) => updatePlanField('buttonText', e.target.value)}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Color"
              value={currentPlan.color}
              onChange={(e) => updatePlanField('color', e.target.value)}
              sx={{ mb: 2 }}
            />
          </Grid>
          {/* New fields for Stripe Price IDs */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Fixed Price ID"
              value={currentPlan.fixedPriceId || ''}
              onChange={(e) => updatePlanField('fixedPriceId', e.target.value)}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Usage Price ID"
              value={currentPlan.usagePriceId || ''}
              onChange={(e) => updatePlanField('usagePriceId', e.target.value)}
              sx={{ mb: 2 }}
            />
          </Grid>
          {/* New field for Contact Limit */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Contact Limit"
              type="number"
              value={currentPlan.contactLimit || 0}
              onChange={(e) => updatePlanField('contactLimit', parseInt(e.target.value))}
              sx={{ mb: 2 }}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Features
        </Typography>
        {currentPlan.features && currentPlan.features.map((feature, idx) => (
          <Grid container spacing={1} key={idx} alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={10}>
              <TextField
                fullWidth
                label={`Feature ${idx + 1}`}
                value={feature}
                onChange={(e) => updateFeature(idx, e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="outlined" color="error" onClick={() => removeFeature(idx)}>
                Remove
              </Button>
            </Grid>
          </Grid>
        ))}
        <Box sx={{ mt: 1 }}>
          <Button variant="contained" onClick={addFeature}>
            Add Feature
          </Button>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Pricing Table
        </Typography>
        {currentPlan.pricingTable && currentPlan.pricingTable.map((row, idx) => (
          <Grid container spacing={1} key={idx} alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Max Contacts"
                type="number"
                value={row.contacts}
                onChange={(e) => updatePricingRow(idx, 'contacts', e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Price"
                type="number"
                inputProps={{ step: "0.01" }}
                value={row.price}
                onChange={(e) => updatePricingRow(idx, 'price', e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <Button variant="outlined" color="error" onClick={() => removePricingRow(idx)}>
                Remove
              </Button>
            </Grid>
          </Grid>
        ))}
        <Box sx={{ mt: 1 }}>
          <Button variant="contained" onClick={addPricingRow}>
            Add Pricing Row
          </Button>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ textAlign: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Box>
    </Paper>
  );
};

export default PricingSettings;
