import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../backend/firebase";
import ChatPhone from "../../components/AIChatSimulator/ChatPhone";
import "./AIChatBuilder.scss";

export default function AIChatBuilder() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageId = queryParams.get("pageId") || "6666";
  if (!pageId) {
    console.error("Missing pageId in URL");
  }

  // State to hold any previously saved flow from Firestore.
  const [savedFlow, setSavedFlow] = useState(null);

  // Updated system instructions. The instructions tell the AI:
  // • To ask for basic business info.
  // • For each service (e.g., Rooms, Spa, Dining) to ask whether the response should be simple, generic template, carousel generic, or button‐based.
  // • If the user selects a carousel generic message, wait until the user provides:
  //    - A required title,
  //    - A required image URL (prompting file upload if needed),
  //    - And at least one button (each with both a title and a target action).
  // • Similarly for button‐based responses.
  // • Also, if the service involves contact information (phone, email), never fabricate details.
  // • When the conversation ends (“Save my flow” or “We’re done”), append a hidden JSON block with the final structure.
  const systemMessage = {
    role: "system",
    content: `
        You are A6ai, an AI assistant dedicated to helping users build their chatbot in a friendly, conversational manner.
  
        Start by asking:
        "What is the name of your business, and what does it do?"
        
        Once the business name and description are provided, ask:
        "Thank you for sharing that. What specific services or amenities does your business offer?" 
        
        Then, for each service (e.g. Rooms, Spa, Dining), ask:
        "For [Service], would you like to use a simple text message, a generic template message, a carousel of generic template messages, or a button-based response?"
  
        For a carousel generic message:
        • Ask, "How many items do you need in the carousel for [Service]?"
        • For each carousel item, require:
           - **Title** (required)
           - **Image URL** (required – prompt the user to upload an image if needed)
           - **Buttons** (at least one required; each button must have a title and a target action such as a URL)
           - Optionally, a subtitle and a default action.
        Do not proceed until you have received all required information for every carousel item.
  
        For a button-based response:
        • Ask for the message text.
        • Then ask for the details for each button – each button must have a title, text, and a target action (for example, a URL or postback). At least one button is required (maximum three).
  
        If the service involves bookings or contact details, ALWAYS ask the user for their phone number or email if not already provided, and NEVER make up any details—use only the data provided by the user.
  
        When the user says "Save my flow" or "We're done", reply with a friendly summary such as:
        "Fantastic! Your chatbot flow for [Business Name] has been successfully created. Thank you for building it with me. If you need further assistance, feel free to reach out. Have a great day!"
        
        Then, append a hidden JSON block (between [hiddenFlow] and [/hiddenFlow]) with this structure:
        
        {
          "generalInfo": {
            "name": "<Business Name>",
            "instructions": "<Any extra instructions you gathered>"
          },
          "contacts": {
            "phone": "<Provided phone number or null>",
            "email": "<Provided email or null>",
            "bookingURL": "<Provided booking URL or null>"
          },
          "chatbotFlow": {
            "welcome": "<Welcome message text>",
            "nodes": { ... }  // All other flow messages arranged as built
          }
        }
        
        DO NOT show this JSON block to the user.
    `,
  };
  

  const initialAssistantMessage = {
    role: "assistant",
    content: "Hi there! I’m A6, your AI assistant. Let’s get your chatbot set up. 🚀 To start, what’s the name of your business, and how would you describe what it does?",
  };

  // All messages use the "content" property.
  const [messages, setMessages] = useState([systemMessage, initialAssistantMessage]);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  // For file attachments.
  const [fileToUpload, setFileToUpload] = useState(null);

  const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
  const MODEL = "gpt-3.5-turbo";

  // Regex for detecting markdown image syntax.
  const imageRegex = /\[([^\]]+)\]\((https?:\/\/.*\.(?:png|jpg|jpeg|gif)(\?.*)?)\)/i;

  // Auto-scroll to bottom when messages update.
  useEffect(() => {
    const container = document.querySelector(".messages-container");
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  // Fetch any saved chatbot flow from Firestore.
  useEffect(() => {
    if (!pageId) return;
    const fetchSavedFlow = async () => {
      try {
        const docRef = doc(db, "clients", pageId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSavedFlow(docSnap.data());
        } else {
          console.log("No saved flow found for page:", pageId);
        }
      } catch (error) {
        console.error("Error fetching saved flow:", error);
      }
    };
    fetchSavedFlow();
  }, [pageId]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle file selection.
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFileToUpload(e.target.files[0]);
    }
  };

  // Upload file to Firebase Storage under clients/{pageId}/chat-uploads.
  const uploadFileToFirebase = async (file) => {
    try {
      const storage = getStorage();
      const fileRef = ref(storage, `clients/${pageId}/chat-uploads/${file.name}`);
      await uploadBytes(fileRef, file);
      return await getDownloadURL(fileRef);
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  };

  // Send message (with text and optional file attachment).
  const handleSend = async () => {
    if (!inputValue.trim() && !fileToUpload) return;

    let fileLink = null;
    if (fileToUpload) {
      setIsLoading(true);
      fileLink = await uploadFileToFirebase(fileToUpload);
      setFileToUpload(null);
      setIsLoading(false);
    }

    let messageContent = inputValue.trim();
    if (fileLink) {
      messageContent += messageContent
        ? `\n[File Attached](${fileLink})`
        : `[File Attached](${fileLink})`;
    }
    if (!messageContent) return;

    const userMessage = { role: "user", content: messageContent };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setInputValue("");
    setIsLoading(true);

    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: MODEL,
          messages: updatedMessages,
          temperature: 0.7,
        }),
      });
      if (!response.ok) {
        throw new Error(`OpenAI API Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      const aiReply = data.choices[0].message.content;
      setMessages([...updatedMessages, { role: "assistant", content: aiReply }]);
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
      setMessages([
        ...updatedMessages,
        { role: "assistant", content: "Sorry, something went wrong. Please try again later." },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle sending on Enter key press.
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend();
    }
  };

  // Helper to extract file attachment URL from message content.
  const extractFileAttachment = (text) => {
    if (!text) return null;
    const fileRegex = /\[File Attached\]\((https?:\/\/.*\.(?:png|jpg|jpeg|gif)(\?.*)?)\)/i;
    const match = text.match(fileRegex);
    return match ? match[1] : null;
  };

  const renderAssistantMessage = (fullContent) => {
    if (!fullContent) return null;
    const match = fullContent.match(imageRegex);
    if (!match) {
      return <div className="message-text">{fullContent.trim()}</div>;
    }
    let caption = match[1].trim();
    const imageUrl = match[2];
    let remainingText = fullContent.replace(imageRegex, "").trim();
    if (remainingText.toLowerCase().startsWith("here is an image")) {
      remainingText = remainingText.replace(/^here is an image[^a-zA-Z0-9]*/i, "").trim();
    }
    return (
      <div>
        {remainingText && <div className="message-text">{remainingText}</div>}
        {caption && <div className="message-caption">{caption}</div>}
        <img className="message-image" src={imageUrl} alt={caption || "image"} />
      </div>
    );
  };

  const renderUserMessage = (fullContent) => {
    if (!fullContent) return null;
    const fileUrl = extractFileAttachment(fullContent);
    if (fileUrl) {
      return (
        <div className="file-attachment">
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            <img src={fileUrl} alt="Attached file" />
          </a>
        </div>
      );
    }
    return <div className="message-text">{fullContent.trim()}</div>;
  };

  // Send user message and fetch AI response using GPT-3.5-turbo.
  // Append hidden context from the saved flow so that the AI uses the provided data and never fabricates contact details.
  const handleSendMessage = async () => {
    if (!inputValue.trim()) return;
    const userMsg = { role: "user", content: inputValue.trim() };
    let updatedMessages = [...messages, userMsg];
    setMessages(updatedMessages);
    setInputValue("");

    let additionalContext = "";
    if (savedFlow) {
      additionalContext = `Chatbot Flow Context: Business Name is "${savedFlow.name || "unknown"}", Services: ${
        savedFlow.services ? Object.keys(savedFlow.services).join(", ") : "none"
      }. IMPORTANT: Do NOT fabricate any phone numbers, emails, or contact details. Use only the provided information.`;
    } else {
      additionalContext = `No saved flow data available. Please ask the user for any missing contact details.`;
    }
    const messagesForAPI = [...updatedMessages, { role: "system", content: additionalContext }];

    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: MODEL,
          messages: messagesForAPI,
          temperature: 0.7,
        }),
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error("API error response:", errorText);
        throw new Error(`OpenAI API Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      const aiReply = data.choices[0].message.content;
      setMessages([...updatedMessages, { role: "assistant", content: aiReply }]);
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
      setMessages([
        ...updatedMessages,
        { role: "assistant", content: "Sorry, something went wrong. Please try again later." },
      ]);
    }
  };

  const handleKeyDownSend = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Extract hidden JSON block from the last assistant message.
  const handleSave = async () => {
    const lastMsg = messages[messages.length - 1].content;
    const hiddenJsonMatch = lastMsg.match(/\[hiddenFlow\]([\s\S]*?)\[\/hiddenFlow\]/);
    if (!hiddenJsonMatch) {
      alert("It seems your chatbot flow isn't complete yet. Please finish the conversation first.");
      return;
    }
    const jsonStr = hiddenJsonMatch[1];
    try {
      const finalFlow = JSON.parse(jsonStr);
      await setDoc(doc(db, "clients", pageId), finalFlow);
      console.log("Chatbot flow saved successfully!", finalFlow);
      alert("Your chatbot flow has been saved successfully!");
    } catch (error) {
      console.error("Error parsing or saving flow JSON:", error);
      alert("There was an error processing your chatbot flow. Please review your conversation and try again.");
    }
  };

  return (
    <div className="ai-chat-builder-page">
      <div className="ai-chat-builder-card">
        {/* LEFT PANEL: Chat Interface */}
        <div className="left-chat-panel">
          <h2 className="chat-title">A6ai Chat Builder</h2>
          <div className="messages-container">
            {messages
              .filter((msg) => msg.role !== "system")
              .map((msg, idx) => (
                <div key={idx} className={`bubble ${msg.role === "assistant" ? "assistant" : "user"}`}>
                  {msg.role === "assistant"
                    ? renderAssistantMessage(msg.content)
                    : renderUserMessage(msg.content)}
                </div>
              ))}
            {isLoading && (
              <div className="bubble assistant typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="input-area">
            <label className="attach-btn">
              📎
              <input type="file" onChange={handleFileChange} style={{ display: "none" }} />
            </label>
            {fileToUpload && (
              <div className="file-preview">
                <img src={URL.createObjectURL(fileToUpload)} alt="Preview" />
              </div>
            )}
            <input
              type="text"
              placeholder="Type your message..."
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDownSend}
              disabled={isLoading}
            />
            <button onClick={handleSend} disabled={isLoading}>
              Send
            </button>
          </div>
          <div className="save-area">
            <button onClick={handleSave} disabled={isLoading}>
              Save My Chatbot
            </button>
          </div>
        </div>
        {/* RIGHT PANEL: Phone Simulator */}
        <div className="right-phone-panel">
          <ChatPhone pageId={pageId} />
        </div>
      </div>
    </div>
  );
}
