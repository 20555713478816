import React, { useState } from "react";
import { Handle } from "react-flow-renderer";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../backend/firebase"; // Adjust relative path as needed
import "./GenericTemplateNode.scss";

export default function GenericTemplateNode({ id, data }) {
  const [newButton, setNewButton] = useState({ title: "", text: "" });
  const [uploading, setUploading] = useState(false);

  const handleAddButton = () => {
    if (data.buttons && data.buttons.length >= 2) {
      // Only allow up to two buttons.
      return;
    }
    if (!newButton.title.trim() || !newButton.text.trim()) return;
    const updatedButtons = [...(data.buttons || []), newButton];
    data.updateNode(id, { ...data, buttons: updatedButtons });
    setNewButton({ title: "", text: "" });
  };

  const handleUpdateButton = (index, field, value) => {
    const updatedButtons = data.buttons.map((button, i) =>
      i === index ? { ...button, [field]: value } : button
    );
    data.updateNode(id, { ...data, buttons: updatedButtons });
  };

  const handleDeleteButton = (index) => {
    const updatedButtons = data.buttons.filter((_, i) => i !== index);
    data.updateNode(id, { ...data, buttons: updatedButtons });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setUploading(true);
    try {
      // Upload to a folder under images/{pageId}/
      const storagePath = `images/${data.pageId || "default"}/${file.name}_${Date.now()}`;
      const storageRef = ref(storage, storagePath);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      // Update node data with the image URL
      data.updateNode(id, { ...data, image: downloadURL });
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setUploading(false);
    }
  };

  const stopPropagation = (e) => e.stopPropagation();

  return (
    <div className="message-node generic">
      <button className="remove-node" onClick={() => data.removeNode(id)}>
        X
      </button>
      <Handle type="target" position="left" />
      <div className="node-content">
        {/* Read-only DB label */}
        <p className="db-label">Label: {data.label}</p>
        {/* Editable title (separate from label) */}
        <input
          type="text"
          value={data.title || ""}
          onChange={(e) =>
            data.updateNode(id, { ...data, title: e.target.value })
          }
          placeholder="Generic Node Title"
          onMouseDown={stopPropagation}
        />
        {/* Message text */}
        <textarea
          value={data.text}
          onChange={(e) =>
            data.updateNode(id, { ...data, text: e.target.value })
          }
          placeholder="Message Text"
          onMouseDown={stopPropagation}
        />
        {/* Image upload */}
        <input type="file" onChange={handleImageUpload} />
        {uploading ? (
          <p>Uploading...</p>
        ) : (
          data.image && <img src={data.image} alt="Uploaded" />
        )}
        {/* Buttons (max 2) */}
        <div className="buttons">
          {(data.buttons || []).map((button, index) => (
            <div key={index} className="button-item">
              <input
                type="text"
                value={button.title}
                onChange={(e) =>
                  handleUpdateButton(index, "title", e.target.value)
                }
                placeholder="Button Title"
                onMouseDown={stopPropagation}
              />
              <input
                type="text"
                value={button.text}
                onChange={(e) =>
                  handleUpdateButton(index, "text", e.target.value)
                }
                placeholder="Button Text"
                onMouseDown={stopPropagation}
              />
              <button onClick={() => handleDeleteButton(index)}>X</button>
            </div>
          ))}
          {data.buttons && data.buttons.length < 2 && (
            <>
              <input
                type="text"
                value={newButton.title}
                onChange={(e) =>
                  setNewButton({ ...newButton, title: e.target.value })
                }
                placeholder="New Button Title"
                onMouseDown={stopPropagation}
              />
              <input
                type="text"
                value={newButton.text}
                onChange={(e) =>
                  setNewButton({ ...newButton, text: e.target.value })
                }
                placeholder="New Button Text"
                onMouseDown={stopPropagation}
              />
              <button onClick={handleAddButton}>Add Button</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
