import React from "react";
import GenericTemplate from "./GenericTemplate";
import "./Carousel.scss";

export default function Carousel({ templates }) {
  if (!templates || templates.length === 0) return null;
  return (
    <div className="carousel">
      {templates.map((template, index) => (
        <div key={index} className="carousel-item">
          <GenericTemplate template={template} />
        </div>
      ))}
    </div>
  );
}
  