import React from "react";
import "./ButtonMessage.scss";

export default function ButtonMessage({ message, onReply }) {
  // Expect message.buttonMessage to contain:
  // { text: string, buttons: [{ title, text, targetNodeId? }] }
  const { text, buttons } = message.buttonMessage || {};

  return (
    <div className="button-message">
      <p className="button-message-text">{text}</p>
      <div className="button-message-buttons">
        {buttons &&
          buttons.map((btn, index) => (
            <button
              key={index}
              className="button-message-btn"
              onClick={() => onReply({ text: btn.text, targetNodeId: btn.targetNodeId || "" })}
            >
              {btn.title}
            </button>
          ))}
      </div>
      {message.quickReplies && message.quickReplies.length > 0 && (
        <div className="quick-replies">
          {message.quickReplies.map((qr, index) => (
            <button key={index} onClick={() => onReply(qr)}>
              {qr.text}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
