import React, { useState, useEffect, useRef } from "react";
import MessageBubble from "./MessageBubble";
import MessageInput from "./MessageInput";
import { db } from "../../backend/firebase";
import { doc, getDoc } from "firebase/firestore";
import "./ChatWindow.scss";

// Example only: your existing dynamic logic stays the same
const dynamicExtractKeywordTarget = (text, chatFlow) => {
  if (!chatFlow) return null;
  const lowerText = text.toLowerCase();
  const keywordMapping = {
    rooms: "node-2",
    spa: "node-6",
    massage: "node-7",
    sauna: "node-8",
    "main menu": "welcome",
  };
  for (let keyword in keywordMapping) {
    if (lowerText.includes(keyword)) {
      return keywordMapping[keyword];
    }
  }
  return null;
};

export default function ChatWindow({ pageId, isTemplate }) {
  const [messages, setMessages] = useState([]);
  const [chatFlow, setChatFlow] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const welcomeSent = useRef(false);
  
  // Ref to scroll to the bottom when new messages are added.
  const messagesEndRef = useRef(null);

  // Scroll into view whenever messages change.
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (!pageId) {
      console.error("❌ Missing pageId in props.");
      return;
    }
    const fetchChatFlow = async () => {
      setIsTyping(true);
      console.log("🔵 Fetching chatbot data for pageId:", pageId);
      try {
        const collectionName = isTemplate ? "templates" : "clients";
        const docRef = doc(db, collectionName, pageId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const docData = docSnap.data();
          console.log("✅ Firestore Doc Data:", docData);
          if (docData && docData[pageId]) {
            const flowData = docData[pageId];
            setChatFlow(flowData.nodes);
            // Auto-send welcome message
            if (!welcomeSent.current) {
              const firstNode = flowData.nodes["welcome"];
              if (firstNode) {
                setCurrentNode(firstNode);
                sendBotMessage(firstNode);
                welcomeSent.current = true;
              }
            }
          } else {
            console.error("❌ No chatbot data found for pageId:", pageId);
          }
        } else {
          console.error("❌ No document found for pageId:", pageId);
        }
      } catch (error) {
        console.error("🔥 Error fetching chatbot data:", error);
      } finally {
        setIsTyping(false);
      }
    };
    fetchChatFlow();
  }, [pageId, isTemplate]);

  // Insert "Main Menu" quick reply if not at "welcome" node
  const addMainMenuQuickReply = (quickReplies = []) => {
    if (currentNode && currentNode.id !== "welcome") {
      const exists = quickReplies.some(
        (qr) => qr.text.trim().toLowerCase() === "main menu"
      );
      if (!exists) {
        return [...quickReplies, { text: "Main Menu", targetNodeId: "welcome" }];
      }
    }
    return quickReplies;
  };

  const sendBotMessage = (node) => {
    if (!node || !node.message) {
      console.error("❌ No valid message found:", node);
      return;
    }
    setIsTyping(true);
    setTimeout(() => {
      const quickReplies = addMainMenuQuickReply(node.message.quickReplies || []);
      // Handle each message type
      if (node.message.type === "carousel") {
        // Build an array of "genericTemplate" items
        const templates = (node.message.outputs || [])
          .map((outputId) => chatFlow && chatFlow[outputId.trim()])
          .filter((n) => n && n.message && n.message.type === "genericTemplate")
          .map((n) => ({
            title: n.message.title,
            image: n.message.image,
            buttons: n.message.buttons || [],
            text: n.message.text,
          }));
        if (templates.length > 0) {
          setMessages((prev) => [
            ...prev,
            {
              sender: "bot",
              type: "carousel",
              templates,
              quickReplies,
            },
          ]);
        } else {
          setMessages((prev) => [
            ...prev,
            {
              text: "Sorry, no carousel items found.",
              sender: "bot",
              type: "text",
              quickReplies,
            },
          ]);
        }
      } else if (node.message.type === "genericTemplate") {
        setMessages((prev) => [
          ...prev,
          {
            sender: "bot",
            type: "genericTemplate",
            template: {
              title: node.message.title,
              image: node.message.image,
              buttons: node.message.buttons || [],
              text: node.message.text,
            },
            quickReplies,
          },
        ]);
      } else if (node.message.type === "buttonMessage") {
        setMessages((prev) => [
          ...prev,
          {
            sender: "bot",
            type: "buttonMessage",
            buttonMessage: {
              text: node.message.text,
              buttons: node.message.buttons || [],
            },
            quickReplies,
          },
        ]);
      } else {
        // Default text
        setMessages((prev) => [
          ...prev,
          {
            text: node.message.text,
            sender: "bot",
            type: node.message.type || "text",
            quickReplies,
          },
        ]);
      }
      setIsTyping(false);
    }, 500);
  };

  const handleClickedQuickReply = (quickReply) => {
    console.log("User clicked quick reply:", quickReply);
    // "Main Menu" special case
    if (quickReply.text.trim().toLowerCase() === "main menu") {
      if (chatFlow && chatFlow["welcome"]) {
        const welcomeNode = chatFlow["welcome"];
        setCurrentNode(welcomeNode);
        sendBotMessage(welcomeNode);
      }
      return;
    }
    // Add user message
    setMessages((prev) => [
      ...prev,
      { text: quickReply.text, sender: "user", type: "text" },
    ]);
    if (!chatFlow || !currentNode) return;
    const targetId = quickReply.targetNodeId.trim();
    const nextNode = chatFlow[targetId];
    if (nextNode) {
      setIsTyping(true);
      setTimeout(() => {
        setCurrentNode(nextNode);
        sendBotMessage(nextNode);
        setIsTyping(false);
      }, 500);
    } else {
      setMessages((prev) => [
        ...prev,
        { text: "Sorry, I didn't understand that.", sender: "bot", type: "text" },
      ]);
    }
  };

  const handleUserMessage = (userMessage) => {
    console.log("User message:", userMessage);
    const trimmedMsg = userMessage.trim().toLowerCase();
    // Add user message to conversation
    setMessages((prev) => [
      ...prev,
      { text: userMessage, sender: "user", type: "text" },
    ]);
    // Simple greeting
    if (["hi", "hello", "hey"].includes(trimmedMsg)) {
      if (chatFlow && chatFlow["welcome"]) {
        const welcomeNode = chatFlow["welcome"];
        setCurrentNode(welcomeNode);
        sendBotMessage(welcomeNode);
        return;
      }
    }
    // Try matching a quick reply exactly
    if (chatFlow && currentNode && currentNode.message && currentNode.message.quickReplies) {
      const matchedReply = currentNode.message.quickReplies.find(
        (qr) => qr.text.trim().toLowerCase() === trimmedMsg
      );
      if (matchedReply) {
        return handleClickedQuickReply(matchedReply);
      }
    }
    // Fallback: dynamic keyword extraction
    const dynamicTargetId = dynamicExtractKeywordTarget(userMessage, chatFlow);
    if (dynamicTargetId && chatFlow && chatFlow[dynamicTargetId]) {
      const targetNode = chatFlow[dynamicTargetId];
      setIsTyping(true);
      setTimeout(() => {
        setCurrentNode(targetNode);
        sendBotMessage(targetNode);
        setIsTyping(false);
      }, 500);
      return;
    }
    // No match
    setMessages((prev) => [
      ...prev,
      { text: "Sorry, I didn't understand that.", sender: "bot", type: "text" },
    ]);
  };

  return (
    <div className="chat-phone">
      <div className="chat-header">
        <div className="chat-avatar">A6AI</div>
        <p>Business Chat</p>
      </div>

      <div className="chat-body">
        {messages.map((msg, index) => (
          <MessageBubble key={index} message={msg} onReply={handleClickedQuickReply} />
        ))}
        {isTyping && (
          <div className="message assistant">
            <TypingIndicator />
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="chat-footer">
        <MessageInput onSend={handleUserMessage} />
      </div>
    </div>
  );
}

const TypingIndicator = () => (
  <div style={{ display: "flex", gap: "4px" }}>
    <span className="dot" />
    <span className="dot" />
    <span className="dot" />
  </div>
);
