import React, { useState, useEffect } from 'react';

// Material UI Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MessageIcon from '@mui/icons-material/Message';
import ImageIcon from '@mui/icons-material/Image';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate, useLocation } from 'react-router-dom';

// Firebase services
import { db, storage } from '../../backend/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore'; // Import getDoc here

import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Joyride for tutorials
import Joyride, { STATUS } from 'react-joyride';

// Custom styles and components
import './editPage.scss';
import ChatbotPhone from '../../components/ChatbotPhone/ChatbotPhone';

// Tutorial images
import tutorial1 from '../../images/tutorial1.png';
import tutorial2 from '../../images/tutorial2.png';
import tutorial3 from '../../images/tutorial3.png';
import tutorial4 from '../../images/tutorial4.png';
import { useParams } from 'react-router-dom';


export default function EditPage() {
    const { pageId } = useParams(); // This gets the pageId from the URL


    const location = useLocation(); // Use useLocation hook to access the query parameters
    const queryParams = new URLSearchParams(location.search);
    const platform = queryParams.get('platform');

    const [showChatbot, setShowChatbot] = useState(false);




    const [uploadedImage, setUploadedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');


    const [skipToMessageForOption, setSkipToMessageForOption] = useState({});
    const [skipToMessageForSubOption, setSkipToMessageForSubOption] = useState({});



    const handleSkipToMessageChange = (item, level) => {
        if (level === 'option') {
            const updatedSkip = { ...skipToMessageForOption, [item]: !skipToMessageForOption[item] };
            setSkipToMessageForOption(updatedSkip);
            if (updatedSkip[item]) {
                setSubOptions(prev => ({ ...prev, [item]: [item] }));
                setSubSubOptions(prev => ({ ...prev, [item]: [item] }));
                setSelectedOption(item);
                setSelectedSubOption(item);
                setSelectedSubSubOption(item);
            }
        } else if (level === 'subOption') {
            const updatedSkip = { ...skipToMessageForSubOption, [item]: !skipToMessageForSubOption[item] };
            setSkipToMessageForSubOption(updatedSkip);
            if (updatedSkip[item]) {
                setSubSubOptions(prev => ({
                    ...prev,
                    [item]: [...(prev[item] || []), item]  // Ensures a subSubOption is added for the subOption
                }));
                setSelectedSubOption(item);
                setSelectedSubSubOption(item);
            }
        }
    };
    



    const handleImageUpload = async (e, subSubOption) => {
        const file = e.target.files[0];
        if (file) {
            const storageRef = ref(storage, `clients/${file.name}`);

            try {
                const uploadTaskSnapshot = await uploadBytes(storageRef, file);
                const downloadedUrl = await getDownloadURL(uploadTaskSnapshot.ref);

                // Update the imageUrl for the specific subSubOption in userInputs
                setUserInputs(prevInputs => ({
                    ...prevInputs,
                    [subSubOption]: {
                        ...prevInputs[subSubOption],
                        imageUrl: downloadedUrl, // Update the imageUrl for the specific subSubOption
                    },
                }));

            } catch (error) {
                console.error("Error uploading image to Firebase Storage:", error);
            }
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            if (!pageId) return;


            const collectionName = platform === 'instagram' ? 'IGclients' : 'clients';
            const docRef = doc(db, collectionName, pageId);


            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();

                // Set business name and business type
                setBusinessName(data.additionalInfo?.businessName || '');
                setBusinessType(data.additionalInfo?.businessType || '');

                // Set options, subOptions, and subSubOptions
                setOptions(data.options || []);
                setSubOptions(data.subOptions || {});
                setSubSubOptions(data.subSubOptions || {});

                // Ensure checkboxes reflect the fetched data
                const allCheckedOptions = {};
                (data.options || []).forEach(option => { allCheckedOptions[option] = true; });
                Object.values(data.subOptions || {}).flat().forEach(subOption => { allCheckedOptions[subOption] = true; });
                Object.values(data.subSubOptions || {}).flat().forEach(subSubOption => { allCheckedOptions[subSubOption] = true; });
                setCheckedOptions(allCheckedOptions);

                // Set messages and their types
                const newUserInputs = {};
                const newSubSubOptionMessageTypes = {};
                Object.entries(data.subSubOptionDetails || {}).forEach(([key, value]) => {
                    newUserInputs[key] = value;
                    newSubSubOptionMessageTypes[key] = value.type || 'normal';
                });
                setUserInputs(newUserInputs);
                setSubSubOptionMessageTypes(newSubSubOptionMessageTypes);

                // Set rules
                const newRulesState = {
                    blockUserAfterInappropriateMessage: data.additionalInfo?.rules?.blockUserAfterInappropriateMessage || false,
                };
                setRulesState(newRulesState);

                console.log("Data fetched successfully");
            } else {
                console.error("No such document!");
            }
        };

        fetchData();
    }, [pageId]);


    const [options, setOptions] = useState([]);
    const [subOptions, setSubOptions] = useState({});
    const [subSubOptions, setSubSubOptions] = useState({});
    const [businessType, setBusinessType] = useState('');

    const updateOptionsForBusinessType = (type) => {
        switch (type) {
            case 'hotels':
                setOptions(['Rooms', 'Dining', 'Activities', 'Events', 'Locations']);
                setSubOptions({
                    Rooms: ['Room Types', 'Rates', 'Availability', 'Promotions', 'Group Bookings'],
                    Dining: ['Menu', 'Special Offers', 'Chefs', 'Reservations', 'Reviews'],
                    Activities: ['Pool', 'Gym', 'Spa', 'Tours', 'Workshops'],
                    Events: ['Weddings', 'Conferences', 'Meetings', 'Parties', 'Shows'],
                    Locations: ['Locations']
                });
                setSubSubOptions({
                    'Room Types': ['Single', 'Double', 'Suite', 'Deluxe', 'Presidential'],

                });
                break;
            case 'scratch':
                // Empty options for the 'Scratch' type
                setOptions([]);
                setSubOptions({});
                setSubSubOptions({});
                break;
            default:
                // Reset or default setup
                setOptions([]);
                setSubOptions({});
                setSubSubOptions({});
        }
    };

    // Update the `setBusinessType` function to handle business type selection
    const handleBusinessTypeChange = (e) => {
        const newType = e.target.value;
        setBusinessType(newType);
        updateOptionsForBusinessType(newType);
    };

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedSubOption, setSelectedSubOption] = useState('');
    const [selectedSubSubOption, setSelectedSubSubOption] = useState('');
    const [checkedOptions, setCheckedOptions] = useState({});



    const [newOption, setNewOption] = useState('');
    const [newSubOption, setNewSubOption] = useState('');
    const [newSubSubOption, setNewSubSubOption] = useState('');
    const [subSubOptionMessageTypes, setSubSubOptionMessageTypes] = useState({});

    const handleMessageTypeChange = (subSubOption, type) => {
        // Find the parent sub-option for the current sub-sub-option
        const parentSubOption = Object.keys(subSubOptions).find(key =>
            subSubOptions[key].includes(subSubOption)
        );
    
        // If the new type is 'template', set all sub-sub-options under the same sub-option to 'template'
        if (type === 'template') {
            const allSubSubOptions = subSubOptions[parentSubOption];
            const updatedTypes = allSubSubOptions.reduce((acc, currentSubSubOption) => {
                acc[currentSubSubOption] = 'template';
                return acc;
            }, {});
    
            setSubSubOptionMessageTypes(prevTypes => ({
                ...prevTypes,
                ...updatedTypes
            }));
    
            // Update userInputs to reflect the new type for all sub-sub-options
            setUserInputs(prevInputs => {
                const newInputs = { ...prevInputs };
                allSubSubOptions.forEach(currentSubSubOption => {
                    newInputs[currentSubSubOption] = {
                        ...newInputs[currentSubSubOption],
                        type: 'template'
                    };
                });
                return newInputs;
            });
        } else {
            // Update the message type for only the specific sub-sub-option
            setSubSubOptionMessageTypes(prevTypes => ({
                ...prevTypes,
                [subSubOption]: type
            }));
    
            // Update userInputs to reflect the new type for the specific sub-sub-option
            setUserInputs(prevInputs => ({
                ...prevInputs,
                [subSubOption]: {
                    ...prevInputs[subSubOption],
                    type: type
                }
            }));
        }
    };
    
    const handleSelectOption = (option) => {
        setSelectedOption(option);
        setSelectedSubOption('');
        setSelectedSubSubOption('');
    };

    // Initialize these state variables as empty strings to avoid uncontrolled to controlled component transition
    const [businessName, setBusinessName] = useState('');
    const [pageID, setPageID] = useState('');


    const handleCreateChatbot = async () => {
        if (!businessName.trim()) {
            alert("Business name is required.");
            return;
        }
    
        const clientData = {
            options: [],
            subOptions: {},
            subSubOptions: {},
            subSubOptionDetails: userInputs,
            additionalInfo: {
                businessName,
                rules: rulesState,
                businessType,
            }
        };
    
        let validationMessage = '';
        let hasValidOptionStructure = false;
    
        for (const option of options) {
            if (checkedOptions[option]) {
                clientData.options.push(option);
                const validSubOptions = subOptions[option]?.filter(subOption => checkedOptions[subOption]);
                if (validSubOptions) {
                    clientData.subOptions[option] = validSubOptions;
                    for (const subOption of validSubOptions) {
                        const validSubSubOptions = subSubOptions[subOption]?.filter(subSubOption => checkedOptions[subSubOption]);
                        if (validSubSubOptions) {
                            clientData.subSubOptions[subOption] = validSubSubOptions;
                            for (const subSubOption of validSubSubOptions) {
                                const details = userInputs[subSubOption];
                                const type = subSubOptionMessageTypes[subSubOption] || 'normal';
    
                                // Add console log to check the type
                                if (!details.type) {
                                    console.log("Missing type for subSubOption:", subSubOption, "Details:", details);
                                }
    
                                clientData.subSubOptionDetails[subSubOption] = { ...details, type };
    
                                switch (type) {
                                    case 'normal':
                                        if (!details?.message?.trim()) {
                                            validationMessage = 'A message is required for the normal message type.';
                                        }
                                        break;
                                    case 'button':
                                        if (!details?.button1Text?.trim() && !details?.button2Text?.trim()) {
                                            validationMessage = 'At least one button is required for the button message type.';
                                        }
                                        break;
                                    case 'template':
                                        if (!details?.title?.trim() || !details?.description?.trim()) {
                                            validationMessage = 'Title and description are required for the template message type.';
                                        } else if (!details?.imageUrl?.trim()) {
                                            validationMessage = 'An image URL is required for the template message type.';
                                        }
                                        break;
                                    default:
                                        validationMessage = `Invalid message type: ${type}`;
                                }
    
                                if (validationMessage) {
                                    alert(validationMessage);
                                    return;
                                }
    
                                hasValidOptionStructure = true;
                            }
                        }
                    }
                }
            }
        }
    
        if (!hasValidOptionStructure) {
            alert("At least one complete option structure (Option > Sub-Option > Sub-Sub-Option > Details) is required.");
            return;
        }
    
        const collectionName = platform === 'instagram' ? 'IGclients' : 'clients';
        const docRef = doc(db, collectionName, pageId || 'new');
    
        try {
            await setDoc(docRef, clientData);
            console.log("Chatbot configuration updated successfully with doc ID:", pageId);
            alert("Chatbot updated successfully!");
        } catch (error) {
            console.error("Error updating chatbot configuration in Firestore:", error);
            alert("Failed to update chatbot configuration.");
        }
    };
    


    const handleSelectSubOption = (subOption) => {
        setSelectedSubOption(subOption);
        setSelectedSubSubOption('');
    };


    const handleSelectSubSubOption = (subSubOption) => {
        setSelectedSubSubOption(subSubOption);
    };

    const handleCheckOption = (option) => {
        setCheckedOptions(prev => ({ ...prev, [option]: !prev[option] }));
    };

    const handleAddOption = () => {
        if (newOption) {
            setOptions(prevOptions => [...prevOptions, newOption]);
            setNewOption('');
        }
    };

    const handleAddSubOption = () => {
        if (selectedOption && newSubOption) {
            setSubOptions(prevSubOptions => ({
                ...prevSubOptions,
                [selectedOption]: [...(prevSubOptions[selectedOption] || []), newSubOption]
            }));
            setSubSubOptions(prevSubSubOptions => ({
                ...prevSubSubOptions,
                [newSubOption]: prevSubSubOptions[newSubOption] || []
            }));
            setNewSubOption('');
        }
    };

    const handleAddSubSubOption = () => {
        if (selectedSubOption && newSubSubOption) {
            // Add the new sub-sub-option to the existing ones
            setSubSubOptions(prevSubSubOptions => ({
                ...prevSubSubOptions,
                [selectedSubOption]: [...(prevSubSubOptions[selectedSubOption] || []), newSubSubOption]
            }));

            // Check if any existing sub-sub-option under the same sub-option is 'template'
            const currentSubSubOptions = subSubOptions[selectedSubOption] || [];
            const isAnyTemplate = currentSubSubOptions.some(subSubOption => subSubOptionMessageTypes[subSubOption] === 'template');

            // Set the message type for the new sub-sub-option
            setSubSubOptionMessageTypes(prevTypes => ({
                ...prevTypes,
                [newSubSubOption]: isAnyTemplate ? 'template' : 'normal'  // Default to 'normal' if no templates are present
            }));

            // Clear the input after adding
            setNewSubSubOption('');
        }
    };


    const [messageType, setMessageType] = useState('normal');
    const [userInputs, setUserInputs] = useState({});

    const handleInputChange = (subSubOption, field, value) => {
        setUserInputs(prevInputs => ({
            ...prevInputs,
            [subSubOption]: {
                ...prevInputs[subSubOption],
                [field]: value || '' // Set to empty string if value is undefined or null
            }
        }));
    };


    const [rulesState, setRulesState] = useState({
        replyToStoryTagged: false,
        blockUserAfterInappropriateMessage: false,
    });

    const handleToggle = (rule) => {
        setRulesState(prevState => ({ ...prevState, [rule]: !prevState[rule] }));
    };


    const renderInputs = (subSubOption) => {
        const subSubOptionInputs = userInputs[subSubOption] || {};
        const currentMessageType = subSubOptionMessageTypes[subSubOption] || 'normal';





        return (
            <>
                {currentMessageType === 'normal' && (
                    <div className="input-group">
                        <label className="input-label">Message</label>
                        <input
                            type="text"
                            placeholder="Enter your message"
                            className="custom-input"
                            value={userInputs[subSubOption]?.message || ''}
                            onChange={(e) => handleInputChange(subSubOption, 'message', e.target.value)}
                            required
                        />

                    </div>
                )}

                {currentMessageType === 'button' && (
                    <>
                        <div className="input-group">
                            <label className="input-label">Button 1 Text</label>
                            <input
                                type="text"
                                placeholder="Button 1 Text"
                                className="custom-input"
                                value={subSubOptionInputs.button1Text || ''}
                                onChange={(e) => handleInputChange(subSubOption, 'button1Text', e.target.value)}
                                required
                            />
                        </div>
                        <div className="button-functionality">
                            <label className="input-label">Button 1 Type</label>
                            <select
                                className="custom-input"
                                value={subSubOptionInputs.button1Type || ''}
                                onChange={(e) => handleInputChange(subSubOption, 'button1Type', e.target.value)}
                                required
                            >
                                <option value="url">URL</option>
                                <option value="mobile">Mobile Number</option>
                            </select>
                            <label className="input-label">Button 1 Value</label>
                            <input
                                type="text"
                                placeholder="Button 1 Value"
                                className="custom-input"
                                value={subSubOptionInputs.button1Value || ''}
                                onChange={(e) => handleInputChange(subSubOption, 'button1Value', e.target.value)}
                                required
                            />
                        </div>

                        {/* Button 2 Inputs */}
                        <div className="input-group">
                            <label className="input-label">Button 2 Text</label>
                            <input
                                type="text"
                                placeholder="Button 2 Text"
                                className="custom-input"
                                value={subSubOptionInputs.button2Text || ''}
                                onChange={(e) => handleInputChange(subSubOption, 'button2Text', e.target.value)}
                            />
                        </div>
                        <div className="button-functionality">
                            <label className="input-label">Button 2 Type</label>
                            <select
                                className="custom-input"
                                value={subSubOptionInputs.button2Type || ''}
                                onChange={(e) => handleInputChange(subSubOption, 'button2Type', e.target.value)}
                            >
                                <option value="url">URL</option>
                                <option value="mobile">Mobile Number</option>
                            </select>
                            <label className="input-label">Button 2 Value</label>
                            <input
                                type="text"
                                placeholder="Button 2 Value"
                                className="custom-input"
                                value={subSubOptionInputs.button2Value || ''}
                                onChange={(e) => handleInputChange(subSubOption, 'button2Value', e.target.value)}
                            />
                        </div>
                    </>
                )}


                {currentMessageType === 'template' && (
                    <>
                        <div className="input-group">
                            <label className="input-label">Image URL</label>
                            <input
                                type="text"
                                placeholder="Enter image URL or upload below"
                                className="custom-input"
                                value={userInputs[selectedSubSubOption]?.imageUrl || ''}
                                onChange={(e) => handleInputChange(selectedSubSubOption, 'imageUrl', e.target.value)}
                                required
                            />

                        </div>

                        <div className="input-group">
                            <label className="input-label">Or Upload Image</label>
                            <input
                                type="file"
                                accept="image/*"
                                className="custom-input"
                                onChange={(e) => handleImageUpload(e, selectedSubSubOption)}
                            />
                        </div>
                        <div className="input-group">
                            <label className="input-label">Title</label>
                            <input
                                type="text"
                                placeholder="Title"
                                className="custom-input"
                                value={subSubOptionInputs.title || ''}
                                onChange={(e) => handleInputChange(subSubOption, 'title', e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label className="input-label">Description</label>
                            <input
                                type="text"
                                placeholder="Description"
                                className="custom-input"
                                value={subSubOptionInputs.description || ''}
                                onChange={(e) => handleInputChange(subSubOption, 'description', e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label className="input-label">Button 1 Title</label>
                            <input
                                type="text"
                                placeholder="Button 1 Title"
                                className="custom-input"
                                value={subSubOptionInputs.button1Title || ''}
                                onChange={(e) => handleInputChange(subSubOption, 'button1Title', e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label className="input-label">Button 1 URL</label>
                            <input
                                type="text"
                                placeholder="Button 1 URL"
                                className="custom-input"
                                value={subSubOptionInputs.button1URL || ''}
                                onChange={(e) => handleInputChange(subSubOption, 'button1URL', e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label className="input-label">Button 2 Title</label>
                            <input
                                type="text"
                                placeholder="Button 2 Title"
                                className="custom-input"
                                value={subSubOptionInputs.button2Title || ''}
                                onChange={(e) => handleInputChange(subSubOption, 'button2Title', e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label className="input-label">Button 2 URL</label>
                            <input
                                type="text"
                                placeholder="Button 2 URL"
                                className="custom-input"
                                value={subSubOptionInputs.button2URL || ''}
                                onChange={(e) => handleInputChange(subSubOption, 'button2URL', e.target.value)}
                            />
                        </div>
                    </>
                )}

                {currentMessageType === 'default' && <div>Select a message type for {subSubOption}</div>}
            </>
        );
    };





    return (
        <div className="customization-container">

            <div className="customization-page">
                <div className="new-columns-container">
                    <div className="first-column">
                        <div className="page-id-card">
                            <input
                                type="text"
                                placeholder="Page ID"
                                value={pageId} // Set the value to pageId from the URL
                                readOnly // Make the input field read-only if the pageId should not be editable
                                className="page-id-input"
                            />          <input
                                type="text"
                                placeholder="Business Name"
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                            />
                        </div>
                        <div className="rules-card">
                            <h3>Rules</h3>
                     
                            <div className="rule">
                                <p>Block User after Inappropriate Message</p>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={rulesState.blockUserAfterInappropriateMessage}
                                        onChange={() => handleToggle('blockUserAfterInappropriateMessage')}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="second-column">
                        <div className="business-type-container">
                            <select
                                className="business-type-select"
                                value={businessType}
                                onChange={handleBusinessTypeChange}
                                disabled
                            >
                                <option value="">Select Business Type</option>
                                <option value="hotels">Hotels</option>
                                <option value="scratch">Scratch</option>
                            </select>
                        </div>

                        <div className="file-upload-container">
                            <h3>Upload File</h3>
                            <div className="file-drop-area">
                                <span className="file-drop-icon">📂</span> 
                                <p>Drop files here or click to upload</p>
                                <input type="file" className="file-input" disabled />
                            </div>
                        </div>

                    </div>


                </div>


                <div className="columns-container">
                    <div className="column options-column">

                        {options.map((option) => (
                            <div key={option} className={`option ${selectedOption === option ? 'selected' : ''}`} onClick={() => handleSelectOption(option)}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            checked={checkedOptions[option] || false}
                                            onChange={(e) => {
                                                e.stopPropagation(); // Prevent the option selection from firing
                                                handleCheckOption(option);
                                            }}
                                        />
                                        <span style={{ marginLeft: '10px' }}>{option}</span>
                                    </div>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent the option selection from firing
                                            handleSkipToMessageChange(option, 'option');
                                        }}
                                        style={{ marginLeft: '10px', padding: '6px 12px' }}
                                    >
                                        Skip to Message
                                    </button>
                                </div>
                            </div>


                        ))}
                        <div className="input-container">
                            <button onClick={handleAddOption} className="add-option-button">
                                <AddCircleOutlineIcon />
                            </button>
                            <input
                                type="text"
                                value={newOption}
                                onChange={(e) => setNewOption(e.target.value)}
                                placeholder="New Option"
                                className="new-option-input"
                            />
                        </div>

                    </div>

                    <div className="column sub-options-column">
            {selectedOption && subOptions[selectedOption] && subOptions[selectedOption].map((subOption) => (
              <div key={subOption} className={`sub-option ${selectedSubOption === subOption ? 'selected' : ''}`} onClick={() => handleSelectSubOption(subOption)}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      checked={checkedOptions[subOption] || false}
                      onChange={(e) => {
                        e.stopPropagation(); // Prevent the sub-option selection from firing
                        handleCheckOption(subOption);
                      }}
                    />
                    <span style={{ marginLeft: '10px' }}>{subOption}</span>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the sub-option selection from firing
                      handleSkipToMessageChange(subOption, 'subOption');
                    }}
                    style={{ marginLeft: '10px', padding: '6px 12px' }}
                  >
                    Skip to Message
                  </button>
                </div>
              </div>


            ))}
            <div className="input-container">
              <button onClick={handleAddSubOption} className="add-option-button">
                <AddCircleOutlineIcon />
              </button>
              <input
                type="text"
                value={newSubOption}
                onChange={(e) => setNewSubOption(e.target.value)}
                placeholder="New Sub-Option"
                className="new-option-input"
              />
            </div>
          </div>

                    <div className="column sub-sub-options-column">
                        {selectedSubOption && subSubOptions[selectedSubOption] && subSubOptions[selectedSubOption].map((subSubOption) => (
                            <div key={subSubOption} className={`sub-sub-option ${selectedSubSubOption === subSubOption ? 'selected' : ''}`} onClick={() => handleSelectSubSubOption(subSubOption)}>
                                <input type="checkbox" checked={checkedOptions[subSubOption] || false} onChange={() => handleCheckOption(subSubOption)} />
                                {subSubOption}
                            </div>
                        ))}
                        <div className="input-container">
                            <button onClick={handleAddSubSubOption} className="add-option-button">
                                <AddCircleOutlineIcon />
                            </button>
                            <input
                                type="text"
                                value={newSubSubOption}
                                onChange={(e) => setNewSubSubOption(e.target.value)}
                                placeholder="New Sub-Sub-Option"
                                className="new-option-input"
                            />
                        </div>
                    </div>

                    <div className="column message-type-column">
                        <div className="message-type-selector">
                            {selectedSubSubOption && (
                                <select
                                    onChange={(e) => handleMessageTypeChange(selectedSubSubOption, e.target.value)}
                                    value={subSubOptionMessageTypes[selectedSubSubOption] || 'normal'}
                                >
                                    <option value="normal">Normal Message</option>
                                    <option value="button">Button Message</option>
                                    <option value="template">Template Message</option>
                                </select>
                            )}
                        </div>

                        {/* Render the input fields based on the selected message type */}
                        {selectedSubSubOption && renderInputs(selectedSubSubOption)}
                    </div>

                </div>

                <div className="submit-button-container">
                    <button className="btn get-started" onClick={handleCreateChatbot}>Create Chatbot</button>
                    <button className="btn test-chatbot" onClick={() => setShowChatbot(true)}>Test Chatbot</button>

                </div>



                {showChatbot && (
                    <div className="chatbot-container">
                        <button className="close-chatbot" onClick={() => setShowChatbot(false)}>X</button>
                        <ChatbotPhone docId={pageId || "defaultPageId"} />
                    </div>
                )}




            </div>
        </div>
    );
}

