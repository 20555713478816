import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../backend/firebase';
import Modal from 'react-modal';
import Navbar from '../../components/Navbar/Navbar';
import './Payment.scss';
import { loadStripe } from "@stripe/stripe-js";

Modal.setAppElement('#root');

const PaymentPage = () => {
  const [userData, setUserData] = useState(null);
  const [pricingSettings, setPricingSettings] = useState(null);
  const [contacts, setContacts] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const currentUserUID = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUserUID) {
        const userRef = doc(db, 'users', currentUserUID);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserData(userSnap.data());
        }
      }
    };

    const fetchPricingSettings = async () => {
      const pricingRef = doc(db, 'settings', 'pricing');
      const snap = await getDoc(pricingRef);
      if (snap.exists()) {
        setPricingSettings(snap.data());
      } else {
        console.error("No pricing settings found in Firestore.");
      }
    };

    fetchUserData();
    fetchPricingSettings();
  }, [currentUserUID]);

  if (!pricingSettings) {
    return <div>Loading pricing details...</div>;
  }

  const plans = pricingSettings.plans || [];

  // Compute an estimated cost from pricingTable (for display only)
  const computeCost = (plan, numContacts) => {
    if (!plan.pricingTable || plan.pricingTable.length === 0) return 0;
    for (let i = 0; i < plan.pricingTable.length; i++) {
      if (numContacts <= plan.pricingTable[i].contacts) {
        return plan.pricingTable[i].price;
      }
    }
    return plan.pricingTable[plan.pricingTable.length - 1].price;
  };

  // Display price for the base monthly plan
  const getDisplayPrice = (plan) => {
    const amount = (plan.amountCents / 100).toFixed(0);
    const limit = plan.contactLimit || 1500;
    return `$${amount}/month (up to ${limit} contacts)`;
  };

  // Called when user selects a plan
  const handleApplyPlan = async (plan) => {
    if (!auth.currentUser) {
      alert("Please log in to subscribe.");
      navigate("/login");
      return;
    }
    try {
      const user = auth.currentUser;
      const token = await user.getIdToken();

      // We now pass BOTH fixedPriceId and usagePriceId
      const response = await fetch("https://a6ai-f11e1.uc.r.appspot.com/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
          uid: user.uid,
          email: user.email,
          fixedPriceId: plan.fixedPriceId,
          usagePriceId: plan.usagePriceId,
          selectedPlan: plan.name,
          contactLimit: plan.contactLimit
        }),
      });

      const data = await response.json();
      if (data.sessionUrl) {
        window.location.href = data.sessionUrl; // redirect to Stripe Checkout
      } else if (data.error) {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div className="payment-page">
      <Navbar />
      <div className="hero-section">
        <h1>Upgrade Your Chatbots with A6AI</h1>
        <p>Choose the perfect plan and unlock AI-powered automation for your business.</p>
      </div>

      {/* Plan Cards Section */}
      <div className="plans-section">
        {plans.map((plan, index) => (
          <div key={index} className="plan-container">
            <div className="plan-header" style={{ backgroundColor: plan.color }}>
              <h2 className="plan-name">{plan.name}</h2>
              <p className="plan-price">{getDisplayPrice(plan)}</p>
              <button className="select-plan-btn" onClick={() => handleApplyPlan(plan)}>
                {plan.buttonText}
              </button>
              <p className="scaling-info" onClick={() => setIsModalOpen(true)}>
                *View detailed pricing table
              </p>
            </div>
            <div className="plan-body">
              <h3>What’s Included:</h3>
              <ul className="plan-features">
                {plan.features.map((feature, i) => (
                  <li key={i}>{feature}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      {/* Dynamic Contact Calculation Section (for display only) */}
      <div className="pricing-section">
        <h2>Calculate the Estimated Cost</h2>
        <p>Enter the number of contacts to see the estimated cost for each plan.</p>
        <div className="pricing-container">
          <div className="pricing-input">
            <label>Contacts:</label>
            <input
              type="number"
              value={contacts}
              min={0}
              onChange={(e) => setContacts(Number(e.target.value))}
              className="input-spacing"
            />
            <input
              type="range"
              min="0"
              max="2000000"
              step="500"
              value={contacts}
              onChange={(e) => setContacts(Number(e.target.value))}
              className="input-spacing"
            />
          </div>
        </div>
        <div className="estimated-costs">
          {plans.map((plan, index) => (
            <p key={index}>
              Estimated Cost for {plan.name}: ${computeCost(plan, contacts)} / month
            </p>
          ))}
        </div>
      </div>

      {/* Pricing Table Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="pricing-modal"
        overlayClassName="ReactModal__Overlay"
      >
        <div className="modal-content">
          <button className="close-button" onClick={() => setIsModalOpen(false)}>
            X
          </button>
          <h2 className="modal-title">Pricing Tables</h2>
          <table className="pricing-table">
            <thead>
              <tr>
                <th>Max Contacts</th>
                <th>{plans[0]?.name}</th>
                <th>{plans[1]?.name}</th>
              </tr>
            </thead>
            <tbody>
              {plans[0] && plans[1] &&
                plans[0].pricingTable.map((row, idx) => (
                  <tr key={idx}>
                    <td>{row.contacts}</td>
                    <td>${row.price}</td>
                    <td>${plans[1].pricingTable[idx]?.price || '-'}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentPage;


// ✅ Essential Fields to Store on Subscription
// Field	Type	Purpose
// payedTime	timestamp	When the user made the payment.
// selectedPlan	string	The plan they subscribed to ("Standard Chatbot", "AI-Powered Chatbot").
// hasPaid	boolean	Whether the user has an active paid subscription (true/false).
// stripeCustomerId	string	Stripe's unique customer ID for this user.
// stripeSubscriptionId	string	Stripe subscription ID to manage upgrades, cancellations, and renewals.
// subscriptionStartDate	timestamp	The exact date/time the subscription started.
// nextBillingDate	timestamp	The next renewal date (set one month ahead).
// contactsUsed	number	The number of contacts the chatbot has engaged this month.
// extraUsageCost	number	Total extra cost for contacts beyond the limit (auto-charged via Stripe).
// isActiveSubscription	boolean	Whether the subscription is currently active.
// lastInvoiceId	string	The last Stripe invoice for this user.
// planAmountCents	number	The fixed price of their selected plan (e.g., 1500 for $15/month).
// extraUsageRate	number	The per-contact rate for additional contacts beyond the plan limit (0.01 or 0.02).


//emao