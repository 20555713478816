import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../backend/firebase";
import "./ChatPhone.scss";

/**
 * A helper to produce a fallback description for a given trigger.
 */
function getFallbackDescription(trigger) {
  return `Here is some info about "${trigger}". It offers great features and an appealing option.`;
}

export default function ChatPhone({ goal, tasks, imageTriggers, pageId }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSpecial = queryParams.get("special") === "true";
  
  // Check if this component is used in a client demo route.
  const isClientDemo = location.pathname.includes("client-demo");

  // When in client-demo, we want to fetch from "aiTemplates"
  const effectiveCollection = isClientDemo ? "aiTemplates" : (isSpecial ? "aiTemplates" : "clients");

  // Log for debugging.
  useEffect(() => {
    console.log("ChatPhone: Using collection", effectiveCollection);
  }, [effectiveCollection]);

  // Local states for fetched values (only used in client-demo mode).
  const [fetchedGoal, setFetchedGoal] = useState(goal);
  const [fetchedTasks, setFetchedTasks] = useState(tasks);
  const [fetchedImageTriggers, setFetchedImageTriggers] = useState(imageTriggers);

  // If in client-demo mode and pageId exists, fetch the AI data from Firebase.
  useEffect(() => {
    if (isClientDemo && pageId) {
      const fetchData = async () => {
        try {
          const docRef = doc(db, "aiTemplates", pageId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setFetchedGoal(data.generatedGoal || goal);
            setFetchedTasks(data.tasks || tasks);
            setFetchedImageTriggers(data.imageTriggers || imageTriggers);
          } else {
            console.error("No document found for pageId:", pageId);
          }
        } catch (error) {
          console.error("Error fetching AI data:", error);
        }
      };
      fetchData();
    }
  }, [isClientDemo, pageId, goal, tasks, imageTriggers]);

  // Determine the effective values.
  const effectiveGoal = isClientDemo ? fetchedGoal : goal;
  const effectiveTasks = isClientDemo ? fetchedTasks : tasks;
  const effectiveImageTriggers = isClientDemo ? fetchedImageTriggers : imageTriggers;

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const messagesEndRef = useRef(null);

  // Regex to detect markdown image: [caption](url)
  const imageRegex = /\[([^\]]+)\]\((https?:\/\/.*\.(?:png|jpg|jpeg|gif)(\?.*)?)\)/i;

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  /**
   * Renders an assistant message.
   */
  const renderAssistantMessage = (fullText) => {
    const match = fullText.match(imageRegex);
    if (!match) {
      const trimmed = fullText.trim();
      return <div className="message-text">{trimmed}</div>;
    }
    let caption = match[1].trim();
    const imageUrl = match[2];
    let remainingText = fullText.replace(imageRegex, "").trim();
    const lowerRemaining = remainingText.toLowerCase();
    if (lowerRemaining.startsWith("here is an image")) {
      remainingText = remainingText.replace(/^here is an image[^a-zA-Z0-9]*/i, "").trim();
    }
    let usedTrigger = null;
    const captionLower = caption.toLowerCase().trim();
    if (captionLower === "!" || captionLower === "") {
      usedTrigger = findTriggerInText(effectiveImageTriggers, fullText);
    } else {
      usedTrigger = effectiveImageTriggers.find(
        (img) => captionLower === img.trigger.toLowerCase()
      );
    }
    let fallbackText = "";
    if (usedTrigger) {
      fallbackText = getFallbackDescription(usedTrigger.trigger);
      caption = "";
    }
    if (!remainingText && !caption && usedTrigger) {
      remainingText = fallbackText;
    }
    return (
      <div>
        {remainingText && <div className="message-text">{remainingText}</div>}
        {caption && <div className="message-caption">{caption}</div>}
        <img className="message-image" src={imageUrl} alt={caption || "image"} />
      </div>
    );
  };

  /**
   * Attempts to find a matching trigger in the text.
   */
  const findTriggerInText = (triggers, text) => {
    const lower = text.toLowerCase();
    for (const img of triggers) {
      if (lower.includes(img.trigger.toLowerCase())) {
        return img;
      }
    }
    return null;
  };

  /**
   * Called when the user sends a message.
   */
  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;
    const userMessage = { role: "user", text: inputMessage };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setInputMessage("");
    try {
      const imagesPrompt =
        effectiveImageTriggers && effectiveImageTriggers.length > 0
          ? "\nImages available:\n" +
            effectiveImageTriggers
              .map((img) => `Trigger: "${img.trigger}" -> ${img.url}`)
              .join("\n")
          : "";
      const systemPrompt = `
You are a concise, professional chatbot. All message text must be white.
If the user's message or your response references a trigger word for an image, you must embed that image using markdown syntax: ![Caption](URL).
Include at least one short descriptive sentence about the triggered item (never just "!").
Do not output extraneous text like "Here is an image of..." unless truly needed.

Goal: ${effectiveGoal}
Tasks: ${effectiveTasks.join(", ")}
${imagesPrompt}
${isSpecial ? "\n[Special Mode Active]" : ""}
`;
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            { role: "system", content: systemPrompt },
            ...updatedMessages.map((m) => ({ role: m.role, content: m.text })),
          ],
          temperature: 0.7,
        }),
      });
      if (!response.ok) {
        throw new Error(`OpenAI API Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      const aiResponse = data.choices[0].message.content;
      const botMessage = { role: "assistant", text: aiResponse };
      setMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error("Error calling AI:", error);
    }
  };

  return (
    <div className="chat-phone">
      <div className="chat-header">
        <div className="chat-avatar">A6AI</div>
        <p>Business Chat</p>
      </div>
      <div className="chat-body">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.role}`}>
            {msg.role === "assistant"
              ? renderAssistantMessage(msg.text)
              : <div className="message-text">{msg.text}</div>}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-footer">
        <input
          type="text"
          placeholder="Write a message..."
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
        />
        <button onClick={handleSendMessage}>➤</button>
      </div>
    </div>
  );
}
