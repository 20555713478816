import React from "react";
import { Handle } from "react-flow-renderer";
import "./NormalMessageNode.scss";

export default function NormalMessageNode({ id, data }) {
  const stopPropagation = (e) => e.stopPropagation();

  return (
    <div className="message-node normal">
      <button
        className="remove-node"
        onClick={() => data.removeNode(id)} // Call the removeNode function
      >
        X
      </button>
      <Handle type="target" position="left" />
      <div className="node-content">
        <input
          type="text"
          value={data.label}
          onChange={(e) =>
            data.updateNode(id, { ...data, label: e.target.value })
          }
          placeholder="Node Title"
          onMouseDown={stopPropagation}
        />
        <textarea
          value={data.text}
          onChange={(e) =>
            data.updateNode(id, { ...data, text: e.target.value })
          }
          placeholder="Message Text"
          onMouseDown={stopPropagation}
        />
      </div>
    </div>
  );
}
