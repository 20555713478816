import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../backend/firebase";
import ChatPhone from "./ChatPhone";
import "./AIChatForm.scss";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist/build/pdf";

// Set the workerSrc to match your installed pdfjs-dist version (4.10.38)
GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + "/pdf.worker.min.mjs";

// Predefined category options for the modal in special mode
const categoryOptions = [
  "Hotel",
  "Restaurant",
  "Travel",
  "Marketing",
  "Support",
  "Sales",
  "E-commerce",
  "Education",
  "Healthcare",
  "Finance",
  "Real Estate",
  "Entertainment",
  "Technology",
  "Lifestyle",
  "Retail",
  "Hospitality",
  "Fitness",
  "Beauty",
  "Automotive",
  "Other"
];

export default function AIChatForm({ pageId }) {
  // Read URL parameters to determine if we're in special mode and which collection to use.
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSpecial = queryParams.get("special") === "true";
  const collectionName = isSpecial ? "aiTemplates" : "clients";

  const [aiInstructions, setAiInstructions] = useState("");
  const [businessContext, setBusinessContext] = useState("");
  const [loading, setLoading] = useState(false);
  const [generatedGoal, setGeneratedGoal] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [imageTriggers, setImageTriggers] = useState([]);

  // PDF states
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfContent, setPdfContent] = useState("");
  const [showPdfModal, setShowPdfModal] = useState(false);

  // Business Name & Type (for non-special mode)
  const [businessName, setBusinessName] = useState("");
  const [businessType, setBusinessType] = useState("");

  // New states for extra template info (used in special mode)
  const [templateName, setTemplateName] = useState("");
  const [category, setCategory] = useState("");

  // New state to trigger the modal when extra info is missing
  const [showSpecialModal, setShowSpecialModal] = useState(false);

  // New state variables for messages
  const [errorMessage, setErrorMessage] = useState("");
  const [pdfUploadSuccess, setPdfUploadSuccess] = useState("");

  const instructionsRef = useRef(null);
  const contextRef = useRef(null);

  // Fetch existing document from the proper collection
  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, collectionName, pageId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (!isSpecial) {
            // For non-special mode, get business details from additionalInfo
            const addInfo = data.additionalInfo || {};
            setBusinessName(addInfo.businessName || "");
            setBusinessType(addInfo.businessType || "");
          } else {
            // In special mode, pre-fill extra info if available
            setTemplateName(data.templateName || "");
            setCategory(data.category || "");
          }
          setAiInstructions(data.aiInstructions || "");
          setBusinessContext(data.businessContext || "");
          setGeneratedGoal(data.generatedGoal || null);
          setTasks(data.tasks || []);
          if (data.imageTriggers) {
            const restored = data.imageTriggers.map((img) => ({
              file: null,
              url: img.url,
              trigger: img.trigger,
            }));
            setImageTriggers(restored);
          }
        }
      } catch (err) {
        console.error("Error loading client data:", err);
      }
    };
    fetchData();
  }, [pageId, collectionName, isSpecial]);

  // Helper to upload images to Firebase Storage (adjusting path based on collection)
  const uploadFile = async (file) => {
    const storage = getStorage();
    const fileRef = ref(storage, `${collectionName}/${pageId}/images/${file.name}`);
    await uploadBytes(fileRef, file);
    return await getDownloadURL(fileRef);
  };

  // Image upload handling
  const handleAddImageTrigger = () => {
    setImageTriggers((prev) => [
      ...prev,
      { file: null, url: "", trigger: "" },
    ]);
  };

  const handleFileChange = async (index, e) => {
    const file = e.target.files[0];
    if (!file) return;
    try {
      const url = await uploadFile(file);
      const updated = [...imageTriggers];
      updated[index].file = file;
      updated[index].url = url;
      setImageTriggers(updated);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload image. Please try again.");
    }
  };

  const handleTriggerChange = (index, e) => {
    const updated = [...imageTriggers];
    updated[index].trigger = e.target.value;
    setImageTriggers(updated);
  };

  // PDF extraction using pdfjs-dist
  const extractPdfText = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await getDocument({ data: arrayBuffer }).promise;
    let fullText = "";
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      const pageText = textContent.items.map((item) => item.str).join(" ");
      fullText += pageText + "\n";
    }
    return fullText;
  };

  const handlePdfUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (file.type !== "application/pdf") {
      alert("Please upload a PDF file.");
      return;
    }
    setPdfFile(file);
    const text = await extractPdfText(file);
    setPdfContent(text);
    setShowPdfModal(false);
    setPdfUploadSuccess("PDF successfully uploaded.");
    setTimeout(() => setPdfUploadSuccess(""), 3000);
  };

  const openPdfModal = () => setShowPdfModal(true);
  const closePdfModal = () => setShowPdfModal(false);

  // Function to save data into Firestore
  const saveData = async () => {
    setLoading(true);
    try {
      const imagesToStore = imageTriggers.map(({ url, trigger }) => ({ url, trigger }));
      const docRef = doc(db, collectionName, pageId);
      const docSnap = await getDoc(docRef);
      let existingData = {};
      if (docSnap.exists()) {
        existingData = docSnap.data();
      }
      
      // Build the new data object
      const newData = {
        ...existingData,
        aiInstructions,
        businessContext,
        generatedGoal,
        tasks,
        imageTriggers: imagesToStore,
        pdfFile: pdfFile ? { name: pdfFile.name } : null,
      };

      if (isSpecial) {
        // Save templateName and category as top-level fields in special mode.
        newData.templateName = templateName;
        newData.category = category;
      } else {
        // For non-special mode, keep business details inside additionalInfo.
        let addInfo = existingData.additionalInfo || {};
        addInfo.businessName = businessName;
        addInfo.businessType = businessType;
        newData.additionalInfo = addInfo;
      }
      await setDoc(docRef, newData, { merge: true });
      alert("Data saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Failed to save data. Please try again.");
    }
    setLoading(false);
  };

  // When saving, if in special mode and either templateName or category is missing, show the modal.
  const handleSave = async () => {
    if (!generatedGoal || tasks.length === 0) {
      alert("Please generate the Goal & Tasks before saving.");
      return;
    }
    if (isSpecial && (!templateName || !category)) {
      setShowSpecialModal(true);
      return;
    }
    saveData();
  };

  const handleGenerate = async () => {
    const canGenerate =
      pdfFile ||
      (businessName.trim() &&
        businessType.trim() &&
        aiInstructions.trim() &&
        businessContext.trim());
    if (!canGenerate) {
      setErrorMessage("Please fill in all required fields or upload a PDF file.");
      return;
    }
    setErrorMessage("");
    setLoading(true);
    try {
      const contentToSend = pdfFile
        ? `PDF Content: ${pdfContent}`
        : `AI Instructions: ${aiInstructions}\nBusiness Context: ${businessContext}`;

      let imagesPrompt = "";
      if (imageTriggers.length > 0) {
        imagesPrompt = `
Images available:
${imageTriggers
  .map((img) => `Trigger: "${img.trigger}" -> ${img.url}`)
  .join("\n")}
If a user's message contains one of these triggers, reply with an image embed (markdown format).
        `;
      }

      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content:
                `You are an expert AI chatbot assistant. Your responses must be concise, professional, and in white text.
When a user's message contains a trigger word for an image, reply with a brief caption and embed the image using markdown.
Goal: ${generatedGoal || ""}
Tasks: ${tasks.join(", ")}
${imagesPrompt}`,
            },
            {
              role: "user",
              content: `Based on the following, generate a **Goal** and a **structured list of tasks**.

**Content:**  
${contentToSend}

---
**IMPORTANT:**  
- The **Goal** must be a clear, structured sentence describing the chatbot’s purpose.
- The **Tasks** must be detailed, actionable steps starting with a dash (-).
- Do not leave the goal empty.

**Response Format (DO NOT DEVIATE):**
---
**Goal:**  
[A clear, structured sentence about the chatbot’s purpose.]

**Tasks:**  
- [Task 1: detailed task]
- [Task 2: detailed task]
- [Task 3: detailed task]
- [Task 4: detailed task as needed]
---
Ensure the Goal is always included.`,
            },
          ],
          temperature: 0.5,
        }),
      });
      if (!response.ok) {
        throw new Error(`OpenAI API Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      const aiOutput = data.choices[0].message.content;

      const goalMatch = aiOutput.match(/\*\*Goal:\*\*\s*(.+)/);
      const tasksMatch = aiOutput.match(/\*\*Tasks:\*\*\s*([\s\S]+)/);

      const goalText = goalMatch ? goalMatch[1].trim() : "";
      const tasksText = tasksMatch ? tasksMatch[1].trim() : "";

      const extractedTasks = tasksText
        .split("\n")
        .filter((line) => line.startsWith("-"))
        .map((line) => line.replace(/^-/, "").trim());

      setGeneratedGoal(
        goalText ||
          "This chatbot handles customer interactions, FAQs, and guides users professionally."
      );
      setTasks(extractedTasks);
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
      alert("Failed to generate response.");
    }
    setLoading(false);
  };

  const handleGenerateAgain = () => {
    setGeneratedGoal(null);
    setTasks([]);
  };

  return (
    <div className="ai-chat-form">
      <div className="left-panel">
        <h2>
          Automate with AI
          <span className="title-info-icon">ℹ️</span>
          <span className="title-info-tooltip">
            Fill in your business details, add any images, and either provide AI instructions manually or upload a PDF with the required details.
          </span>
        </h2>
        <p>AI can handle FAQs, gather user info, and send links.</p>

        {!generatedGoal ? (
          <>
            <div className="input-section">
              <label>Business Name</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  placeholder="Enter your business name"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              </div>
            </div>
            <div className="input-section">
              <label>Business Type</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  placeholder="Enter your business type"
                  value={businessType}
                  onChange={(e) => setBusinessType(e.target.value)}
                />
              </div>
            </div>
            <div className="input-section">
              <label>Tell AI what to do</label>
              <div className="input-wrapper">
                <textarea
                  ref={instructionsRef}
                  placeholder="Enter AI instructions"
                  value={aiInstructions}
                  onChange={(e) => {
                    setAiInstructions(e.target.value);
                    instructionsRef.current.style.height = "auto";
                    instructionsRef.current.style.height = `${instructionsRef.current.scrollHeight}px`;
                  }}
                />
              </div>
            </div>
            <div className="input-section">
              <label>Give AI context</label>
              <div className="input-wrapper">
                <textarea
                  ref={contextRef}
                  placeholder="Enter business context"
                  value={businessContext}
                  onChange={(e) => {
                    setBusinessContext(e.target.value);
                    contextRef.current.style.height = "auto";
                    contextRef.current.style.height = `${contextRef.current.scrollHeight}px`;
                  }}
                />
              </div>
            </div>

            <h3>Images</h3>
            <div className="images-section">
              {imageTriggers.map((img, index) => (
                <div key={index} className="image-card">
                  {img.url ? (
                    <img src={img.url} alt="preview" />
                  ) : (
                    <div className="no-image">No image yet</div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(index, e)}
                  />
                  <input
                    type="text"
                    placeholder="Trigger text"
                    value={img.trigger}
                    onChange={(e) => handleTriggerChange(index, e)}
                  />
                </div>
              ))}
              <button className="add-image-btn" onClick={handleAddImageTrigger}>
                + Add Image
              </button>
            </div>

            <div className="pdf-upload-section">
              <div className="pdf-upload-label">Upload PDF</div>
              <div className="pdf-buttons">
                <button className="upload-pdf-btn" onClick={openPdfModal}>
                  <span className="upload-icon">📄</span> Upload PDF
                </button>
                <button className="download-pdf-btn" onClick={() => window.location.href="/template.pdf"}>
                  <span className="upload-icon">📄</span> Download Template
                </button>
              </div>
            </div>

            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {pdfUploadSuccess && <div className="success-message">{pdfUploadSuccess}</div>}

            <button
              className={`generate-btn ${loading ? "disabled" : ""}`}
              onClick={handleGenerate}
            >
              {loading ? "Generating..." : "Generate"}
            </button>
          </>
        ) : (
          <div className="generated-section">
            <h3>Generated Output</h3>
            <div className="output-group">
              <label>Goal</label>
              <textarea
                value={generatedGoal}
                onChange={(e) => setGeneratedGoal(e.target.value)}
              />
            </div>
            <div className="output-group">
              <label>Tasks (one per line)</label>
              <textarea
                value={tasks.join("\n")}
                onChange={(e) => setTasks(e.target.value.split("\n"))}
              />
            </div>
            <div className="output-buttons">
              <button className="generate-btn" onClick={handleGenerateAgain} disabled={loading}>
                Generate Again
              </button>
              <button className="save-btn" onClick={handleSave} disabled={loading}>
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="right-panel">
        <ChatPhone
          pageId={pageId}
          goal={generatedGoal}
          tasks={tasks}
          imageTriggers={imageTriggers}
        />
      </div>

      {showPdfModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Upload PDF File</h3>
            <p>
              Please ensure your PDF includes all necessary details (it will replace the AI instructions and context).
            </p>
            <div className="modal-actions">
              <label htmlFor="pdf-upload-input" className="choose-file-btn">
                Choose File
              </label>
              <input
                id="pdf-upload-input"
                type="file"
                accept="application/pdf"
                onChange={handlePdfUpload}
                style={{ display: "none" }}
              />
              <button className="modal-close-btn" onClick={closePdfModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {showSpecialModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Template Information</h3>
            <p>Please enter a Template Name and select a Category.</p>
            <div className="modal-inputs">
              <input
                type="text"
                placeholder="Template Name"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
              <select value={category} onChange={(e) => setCategory(e.target.value)}>
                <option value="">Select Category</option>
                {categoryOptions.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="modal-save-btn"
              onClick={() => {
                if (!templateName || !category) {
                  alert("Please fill in both fields.");
                  return;
                }
                setShowSpecialModal(false);
                saveData();
              }}
            >
              Save Template Info
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
