import React from "react";
import "./GenericTemplate.scss";

export default function GenericTemplate({ template }) {
  if (!template) return null;
  return (
    <div className="generic-template">
      {template.image && <img src={template.image} alt={template.title} />}
      <h3>{template.title}</h3>
      <p>{template.text}</p>
      {template.buttons && template.buttons.map((btn, index) => (
        <button key={index} onClick={() => window.open(btn.url, "_blank")}>
          {btn.title}
        </button>
      ))}
    </div>
  );
}
