import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // Icon for Financial
import GroupIcon from '@mui/icons-material/Group'; // Icon for Clients
import AnalyticsIcon from '@mui/icons-material/Analytics'; // Icon for Analytics
import SupportAgentIcon from '@mui/icons-material/SupportAgent'; // Icon for Tickets
import SettingsIcon from '@mui/icons-material/Settings'; // Icon for Pricing/Settings
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import StarBorderIcon from '@mui/icons-material/StarBorder'; // Icon for Premium Users
import DashboardIcon from '@mui/icons-material/Dashboard'; // Icon for Demo
import ViewModuleIcon from '@mui/icons-material/ViewModule'; // Icon for Templates

export const mainListItems = ({ handleMenuItemClick }) => (
  <React.Fragment>
    <ListItemButton onClick={() => handleMenuItemClick('Financial')}>
      <ListItemIcon>
        <MonetizationOnIcon />
      </ListItemIcon>
      <ListItemText primary="Financial" />
    </ListItemButton>
    
    <ListItemButton onClick={() => handleMenuItemClick('Clients')}>
      <ListItemIcon>
        <GroupIcon />
      </ListItemIcon>
      <ListItemText primary="Clients" />
    </ListItemButton>
    
    <ListItemButton onClick={() => handleMenuItemClick('Analytics')}>
      <ListItemIcon>
        <AnalyticsIcon />
      </ListItemIcon>
      <ListItemText primary="Analytics" />
    </ListItemButton>
    
    <ListItemButton onClick={() => handleMenuItemClick('Tickets')}>
      <ListItemIcon>
        <SupportAgentIcon />
      </ListItemIcon>
      <ListItemText primary="Tickets" />
    </ListItemButton>
    
    <ListItemButton onClick={() => handleMenuItemClick('TokenGenerator')}>
      <ListItemIcon>
        <VpnKeyIcon />
      </ListItemIcon>
      <ListItemText primary="Token Generator" />
    </ListItemButton>
    
    <ListItemButton onClick={() => handleMenuItemClick('PremiumUser')}>
      <ListItemIcon>
        <StarBorderIcon />
      </ListItemIcon>
      <ListItemText primary="Premium User" />
    </ListItemButton>
    
    {/* New Pricing Section */}
    <ListItemButton onClick={() => handleMenuItemClick('Pricing')}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Pricing" />
    </ListItemButton>

    {/* New Templates Section */}
    <ListItemButton onClick={() => handleMenuItemClick('Templates')}>
      <ListItemIcon>
        <ViewModuleIcon />
      </ListItemIcon>
      <ListItemText primary="Templates" />
    </ListItemButton>

    {/* New Demo Section */}
    <ListItemButton onClick={() => handleMenuItemClick('Demo')}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Demo" />
    </ListItemButton>
  </React.Fragment>
);
