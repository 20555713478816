// src/Pages/FlowBuilder/FlowBuilder.jsx
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../../backend/firebase";
import { getAuth } from "firebase/auth";

// MUI icons
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import SmartphoneIcon from "@mui/icons-material/Smartphone";

import Sidebar from "../../components/FlowBuilder/Sidebar/Sidebar";
import FlowChart from "../../components/FlowBuilder/FlowChart/FlowChart";
import { flowTemplates } from "../../data/flowTemplates";

// Import your phone simulator
import ChatWindow from "../../components/ChatSimulator/ChatWindow";

import "./FlowBuilder.scss";

export default function FlowBuilder() {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [showPreview, setShowPreview] = useState(false);

  const categoryOptions = [
    "Hotel",
    "Restaurant",
    "Travel",
    "Marketing",
    "Support",
    "Sales",
    "E-commerce",
    "Education",
    "Healthcare",
    "Finance",
    "Real Estate",
    "Entertainment",
    "Technology",
    "Lifestyle",
    "Retail",
    "Hospitality",
    "Fitness",
    "Beauty",
    "Automotive",
    "Other"
  ];
  
  // Additional state for special mode (template name and category)
  const [templateName, setTemplateName] = useState("");
  const [category, setCategory] = useState("");

  // -----------------------------
  // UNIQUE ID MANAGEMENT
  // -----------------------------
  const nextNodeId = useRef(1);
  useEffect(() => {
    const numericIds = nodes.map((node) => {
      const match = node.id.match(/^node-(\d+)$/);
      return match ? parseInt(match[1], 10) : 0;
    });
    if (numericIds.length > 0) {
      const maxId = Math.max(...numericIds);
      nextNodeId.current = maxId + 1;
    }
  }, [nodes]);

  // -----------------------------
  // UNDO / REDO STATE MANAGEMENT
  // -----------------------------
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const isUndoRedo = useRef(false);
  const prevState = useRef({ nodes, edges });

  useEffect(() => {
    if (isUndoRedo.current) {
      isUndoRedo.current = false;
      prevState.current = { nodes, edges };
      return;
    }
    if (
      prevState.current.nodes.length === 0 &&
      prevState.current.edges.length === 0 &&
      nodes.length === 0 &&
      edges.length === 0
    ) {
      prevState.current = { nodes, edges };
      return;
    }
    setUndoStack((prev) => [...prev, prevState.current]);
    setRedoStack([]);
    prevState.current = { nodes, edges };
  }, [nodes, edges]);

  const handleUndo = () => {
    if (undoStack.length === 0) {
      console.log("Nothing to undo");
      return;
    }
    const previousState = undoStack[undoStack.length - 1];
    setUndoStack((prev) => prev.slice(0, prev.length - 1));
    setRedoStack((prev) => [...prev, { nodes, edges }]);
    isUndoRedo.current = true;
    setNodes(previousState.nodes);
    setEdges(previousState.edges);
  };

  const handleRedo = () => {
    if (redoStack.length === 0) {
      console.log("Nothing to redo");
      return;
    }
    const nextState = redoStack[redoStack.length - 1];
    setRedoStack((prev) => prev.slice(0, prev.length - 1));
    setUndoStack((prev) => [...prev, { nodes, edges }]);
    isUndoRedo.current = true;
    setNodes(nextState.nodes);
    setEdges(nextState.edges);
  };

  // --------------------------------------------------
  // LOAD DATA
  // --------------------------------------------------
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageId = queryParams.get("pageId");
  const templateParam = queryParams.get("template"); // e.g., "blank", "sample", or a template id like "11"
  const specialParam = queryParams.get("special"); // should be "true" for special mode

  // Determine special mode based on URL and user UID
  const auth = getAuth();
  const specialUID = "Dw0beJEWDoMtMoR1jPsMqkxqsXx2";
  const specialMode =
    specialParam === "true" &&
    auth.currentUser &&
    auth.currentUser.uid === specialUID;

  useEffect(() => {
    if (!pageId) {
      console.warn("No pageId in URL; starting blank.");
      loadTemplate("blank");
      return;
    }
    // If there's a template query, load the template from Firestore.
    if (templateParam) {
      loadTemplateFromFirestore(templateParam);
    } else {
      loadExistingFlowData(pageId);
    }
  }, [pageId]);

  // Load existing flow from "clients"
  const loadExistingFlowData = async (pid) => {
    try {
      const docRef = doc(db, "clients", pid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const pageFlow = data[pid];
        if (pageFlow && pageFlow.nodes && pageFlow.edges) {
          const loadedNodes = convertNodesObjectToArray(pageFlow.nodes);
          setNodes(loadedNodes);
          setEdges(pageFlow.edges);
          console.log("Loaded existing flow data from Firestore.");
        } else {
          console.log("No existing flow data. Starting blank.");
          loadTemplate("blank");
        }
      } else {
        console.log("No doc found. Starting blank.");
        loadTemplate("blank");
      }
    } catch (error) {
      console.error("Error loading existing data:", error);
      loadTemplate("blank");
    }
  };

  // Load template from Firestore's "templates" collection using templateParam as the document ID.
  const loadTemplateFromFirestore = async (templateId) => {
    try {
      const docRef = doc(db, "templates", templateId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        // The template data is stored under the key matching the doc id.
        const templateFlow = data[templateId];
        if (templateFlow && templateFlow.nodes && templateFlow.edges) {
          const loadedNodes = convertNodesObjectToArray(templateFlow.nodes);
          setNodes(loadedNodes);
          setEdges(templateFlow.edges);
          console.log("Loaded template from Firestore.");
        } else {
          console.log("Template data incomplete, starting blank.");
          loadTemplate("blank");
        }
      } else {
        console.log("No template document found, starting blank.");
        loadTemplate("blank");
      }
    } catch (error) {
      console.error("Error loading template from Firestore:", error);
      loadTemplate("blank");
    }
  };

  // --------------------------------------------------
  // CONVERT NODES
  // --------------------------------------------------
  const convertNodesObjectToArray = (nodesObject) => {
    return Object.values(nodesObject).map((node) => ({
      id: node.id,
      type: node.message?.type || "custom",
      position: {
        x: node.x !== undefined ? node.x : 200,
        y: node.y !== undefined ? node.y : 200,
      },
      data: {
        label: node.label || "",
        text: node.message?.text || "",
        quickReplies: node.message?.quickReplies || [],
        parentId: node.parentId || null,
        buttons: node.message?.buttons || [],
        title: node.message?.title || "",
        image: node.message?.image || "",
        selectedCount: node.message?.selectedCount,
        outputs: node.message?.outputs || [],
        updateNode,
        removeNode,
      },
    }));
  };

  // --------------------------------------------------
  // LOAD TEMPLATE (Local fallback)
  // --------------------------------------------------
  const loadTemplate = (templateName) => {
    const template = flowTemplates[templateName];
    if (!template) {
      console.warn(`Template ${templateName} not found; defaulting to blank.`);
      return loadTemplate("blank");
    }
    const loadedNodes = template.nodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        updateNode,
        removeNode,
      },
    }));
    setNodes(loadedNodes);
    setEdges(template.edges || []);
    console.log(`Loaded local template: ${templateName}`);
  };

  // --------------------------------------------------
  // NODE UPDATES
  // --------------------------------------------------
  const updateNode = (nodeId, newData) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => (node.id === nodeId ? { ...node, data: newData } : node))
    );
  };

  const removeNode = (nodeId) => {
    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== nodeId));
    setEdges((prevEdges) =>
      prevEdges.filter((edge) => edge.source !== nodeId && edge.target !== nodeId)
    );
  };

  // --------------------------------------------------
  // ADD NODE
  // --------------------------------------------------
  const handleAddNode = (type, parentId = null) => {
    const id = `node-${nextNodeId.current}`;
    nextNodeId.current += 1;
    const baseNode = {
      id,
      position: { x: Math.random() * 400, y: Math.random() * 400 },
      data: {
        label: `New ${type} Node`,
        text: "",
        title: "",
        image: "",
        buttons: [],
        quickReplies: [],
        parentId,
        childrenIds: [],
        updateNode,
        removeNode,
      },
    };
    let newNode;
    switch (type) {
      case "normalMessage":
        newNode = { ...baseNode, type: "normalMessage" };
        break;
      case "buttonMessage":
        newNode = { ...baseNode, type: "buttonMessage" };
        break;
      case "genericTemplate":
        newNode = {
          ...baseNode,
          type: "genericTemplate",
          data: { ...baseNode.data, pageId },
        };
        break;
      case "carousel":
        newNode = {
          ...baseNode,
          type: "carousel",
          data: { ...baseNode.data, selectedCount: 2, outputs: [] },
        };
        break;
      default:
        newNode = { ...baseNode, type: "custom" };
    }
    setNodes((prev) => [...prev, newNode]);
  };

  // --------------------------------------------------
  // SAVE FLOW (Internal)
  // --------------------------------------------------
  const handleSave = async () => {
    if (!pageId) {
      console.error("Missing pageId in URL");
      return;
    }
    const updatedNodes = nodes.map((node) => {
      const nodeEdges = edges.filter((edge) => edge.source === node.id);
      if (node.type === "carousel") {
        let outputs = [...(node.data.outputs || [])];
        const count = node.data.selectedCount || 2;
        if (outputs.length < count) {
          outputs = Array.from({ length: count }, (_, i) => outputs[i] ?? null);
        }
        nodeEdges.forEach((edge) => {
          if (
            edge.sourceHandle &&
            edge.sourceHandle.startsWith("carousel-output-")
          ) {
            const index = parseInt(
              edge.sourceHandle.replace("carousel-output-", ""),
              10
            );
            if (!isNaN(index) && index >= 0 && index < outputs.length) {
              outputs[index] = edge.target;
            }
          }
        });
        return { ...node, data: { ...node.data, outputs } };
      } else {
        const updatedQuickReplies = (node.data.quickReplies || []).map((qr) => {
          const matchingEdge = nodeEdges.find(
            (edge) =>
              edge.quickReplyText &&
              edge.quickReplyText.trim() === qr.text.trim()
          );
          if (matchingEdge) {
            return { ...qr, targetNodeId: matchingEdge.target };
          }
          return qr;
        });
        return { ...node, data: { ...node.data, quickReplies: updatedQuickReplies } };
      }
    });
    const nodesObject = updatedNodes.reduce((acc, node) => {
      let messageData = {
        text: node.data.text || "",
        type: node.type || "custom",
        quickReplies: node.data.quickReplies || [],
      };
      if (node.type === "genericTemplate") {
        messageData = {
          ...messageData,
          title: node.data.title || "",
          image: node.data.image || "",
          buttons: node.data.buttons || [],
        };
      } else if (node.type === "buttonMessage") {
        messageData = {
          ...messageData,
          buttons: node.data.buttons || [],
        };
      } else if (node.type === "carousel") {
        messageData = {
          ...messageData,
          selectedCount: node.data.selectedCount || 2,
          outputs: node.data.outputs || [],
        };
      }
      acc[node.id] = {
        id: node.id,
        x: node.position.x,
        y: node.position.y,
        label: node.data.label || "",
        parentId: node.data.parentId || null,
        message: messageData,
      };
      return acc;
    }, {});
    const quickReplyEdges = updatedNodes.reduce((acc, node) => {
      const nodeQRs = node.data.quickReplies || [];
      const nodeEdges = nodeQRs
        .filter((qr) => qr.targetNodeId)
        .map((qr, index) => ({
          source: node.id,
          target: qr.targetNodeId,
          sourceHandle: `quickReply-${index}`,
          quickReplyText: qr.text,
        }));
      return acc.concat(nodeEdges);
    }, []);
    const carouselEdges = updatedNodes
      .filter((node) => node.type === "carousel")
      .reduce((acc, node) => {
        const outputs = node.data.outputs || [];
        const nodeCarouselEdges = outputs.map((target, index) => ({
          source: node.id,
          target,
          sourceHandle: `carousel-output-${index}`,
        }));
        return acc.concat(nodeCarouselEdges);
      }, []);
    const edgesArray = quickReplyEdges.concat(carouselEdges);
    const flowData = {
      [pageId]: {
        nodes: nodesObject,
        edges: edgesArray,
      },
    };
    // If special mode is active, include extra fields.
    if (specialMode) {
      flowData[pageId].templateName = templateName;
      flowData[pageId].category = category;
    }
    // Determine which collection to save into.
    const collectionName = specialMode ? "templates" : "clients";
    try {
      await setDoc(doc(db, collectionName, pageId), flowData);
      console.log("Flowchart saved successfully!", flowData);
    } catch (error) {
      console.error("Error saving flowchart:", error);
    }
  };

  // --------------------------------------------------
  // SAVE OR CONFIRM THEN SAVE
  // --------------------------------------------------
  const handleConfirmAndSave = async (shouldShowPreview = false) => {
    if (!pageId) {
      console.error("Missing pageId in URL");
      return;
    }
    const docRef = doc(db, "clients", pageId);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const confirmOverwrite = window.confirm(
          "A flow with this pageId already exists. Overwrite it?"
        );
        if (!confirmOverwrite) {
          return;
        }
      }
      await handleSave();
      if (shouldShowPreview) {
        setShowPreview(true);
      }
    } catch (err) {
      console.error("Error checking or saving doc:", err);
    }
  };

  // --------------------------------------------------
  // RENDER
  // --------------------------------------------------
  return (
    <div className="flow-builder-page">
      <div className={`phone-preview-container ${showPreview ? "show" : ""}`}>
        {showPreview && (
          <ChatWindow
            pageId={pageId}
            nodes={nodes}
            edges={edges}
            onClose={() => setShowPreview(false)}
          />
        )}
      </div>

      <div className="flow-builder-topbar">
        <div className="topbar-left" />
        <div className="topbar-right">
          <button className="icon-btn" onClick={handleUndo}>
            <UndoIcon />
          </button>
          <button className="icon-btn" onClick={handleRedo}>
            <RedoIcon />
          </button>
          <button className="icon-btn" onClick={() => handleConfirmAndSave(true)}>
            <SmartphoneIcon />
          </button>
          <button className="set-live-btn" onClick={() => handleConfirmAndSave(false)}>
            Set Live
          </button>
        </div>
      </div>

      <div className="flow-builder">
        <Sidebar
          onAddNode={handleAddNode}
          onSave={handleSave}
          specialMode={specialMode}
          templateName={templateName}
          setTemplateName={setTemplateName}
          category={category}
          setCategory={setCategory}
          categories={categoryOptions}
        />
        <FlowChart
          nodes={nodes}
          edges={edges}
          setNodes={setNodes}
          setEdges={setEdges}
          removeNode={removeNode}
        />
      </div>
    </div>
  );
}
