import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../backend/firebase";
import "./Demo.scss";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ChatWindow from "../../components/ChatSimulator/ChatWindow";

export default function Demo() {
  const [defaultPageId, setDefaultPageId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDefaultTemplate = async () => {
      try {
        const templatesRef = collection(db, "templates");
        const querySnapshot = await getDocs(templatesRef);
        console.log("Fetched", querySnapshot.size, "documents from 'templates' collection.");
        let foundDefault = null;
        querySnapshot.forEach((docSnap) => {
          const data = docSnap.data();
          console.log("Checking document:", docSnap.id, "Data:", data);
          // For new structure, default missing genre to "flow"
          if (data.templateName !== undefined) {
            const genre = data.genre || "flow";
            if (genre === "flow" && (data.isDefault === true || data.isDefault === "true")) {
              console.log("Found default template (new structure):", docSnap.id);
              foundDefault = docSnap;
            }
          } else {
            // Fallback for older structure where data is nested under a dynamic key
            const pageKey = Object.keys(data)[0];
            const pageData = data[pageKey] || {};
            console.log("Checking nested data in document:", docSnap.id, "PageKey:", pageKey, "Data:", pageData);
            const genre = pageData.genre || "flow";
            if (genre === "flow" && (pageData.isDefault === true || pageData.isDefault === "true")) {
              console.log("Found default template (old structure):", docSnap.id);
              foundDefault = docSnap;
            }
          }
        });
        if (foundDefault) {
          setDefaultPageId(foundDefault.id);
          console.log("Default template set to document:", foundDefault.id);
        } else {
          console.error("No default template found in 'templates' collection.");
        }
      } catch (error) {
        console.error("Error fetching default template:", error);
      }
      setLoading(false);
    };

    fetchDefaultTemplate();
  }, []);

  if (loading) {
    return (
      <>
        <Navbar />
        <div className="demo-container">
          <p>Loading default chatbot demo...</p>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />
      <div className="demo-container">
        <h1 className="demo-title">Welcome to the Chatbot Customization Demo</h1>
        <p className="demo-headline">
          Experience the power of fully customizable chatbots for Facebook and Instagram right here.
        </p>
        {defaultPageId ? (
          <div className="chat-window-wrapper">
            <ChatWindow pageId={defaultPageId} />
          </div>
        ) : (
          <p className="error-message">No default chatbot demo found.</p>
        )}
      </div>
      <Footer />
    </>
  );
}
