import React from "react";
import "./QuickReplies.scss";

export default function QuickReplies({ replies, onSelect }) {
  if (!replies || replies.length === 0) return null;

  return (
    <div className="quick-replies">
      {replies.map((reply, index) => (
        <button key={index} onClick={() => onSelect(reply)}>
          {reply.text}
        </button>
      ))}
    </div>
  );
}
